.uk-link {
    position     : relative;
    padding-right: 20px;

    span {
        font-weight: bold;
        font-size  : 16px;
        line-height: 24px;
        color      : $primary;
        transition : color 0.3s ease;
    }

    i,
    svg {
        position  : absolute;
        right     : 0;
        top       : 50%;
        transform : translateY(-50%);
        transition: transform 0.4s ease, color 0.3s ease;
        color     : $primary;
        font-size : 14px;
    }

    &:hover {
        text-decoration: none;

        span,
        i,
        svg {
            color: darken($color: $primary, $amount: 6%);
        }

        i,
        svg {
            transform: translateX(6px) translateY(-50%);
        }
    }

    &--black {

        span,
        i,
        svg {
        color: $black;
        }
    }
}

.uk-button {
    font-weight  : bold;
    font-size    : 16px;
    line-height  : 24px;
    padding      : 10px 30px;
    border-radius: $border-radius;
    color: $secondary;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.uk-button-primary,
.c-button-primary {
    cursor: pointer;
    display: block;
    font-weight  : bold;
    font-size    : 16px;
    line-height  : 24px;
    padding      : 10px 30px;
    border-radius: $border-radius;
    color: $white;
    border: 2px solid $secondary;
    background-color: $secondary;
    transition: all .3s ease;

    &:hover,
    &:active,
    &:focus {
        color: $white;
        text-decoration: none;
        background-color: darken($color: $secondary, $amount: 10%);
        border: 2px solid darken($color: $secondary, $amount: 10%);
    }

    // variations
    &--white {
        color: $secondary !important;
        border: 2px solid $white;
        background-color: $white;
    
        &:hover,
        &:active,
        &:focus {
            color: $secondary;
            background-color: $white;
            border: 2px solid $white;
        }
    }
}

.uk-button-secondary,
.c-button-secondary {
    cursor: pointer;
    display: block;
    font-weight  : bold;
    font-size    : 16px;
    line-height  : 24px;
    padding      : 10px 30px;
    border-radius: $border-radius;
    color: $secondary;
    border: 2px solid $secondary;
    background-color: transparent;
    transition: all .3s ease;

    &:hover,
    &:active,
    &:focus {
        color: $white;
        text-decoration: none;
        background-color: darken($color: $secondary, $amount: 10%);
        border: 2px solid darken($color: $secondary, $amount: 10%);
    }

    // variations
    &--white {
        color: $white;
        border: 2px solid $white;

        &:hover,
        &:active,
        &:focus {
            color: $secondary;
            background-color: $white;
            border: 2px solid $white;
        }
    }
}

.c-button-tertiairy {
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $secondary;
    padding-right: 20px;
    padding: 16px 20px 16px 0;
    position: relative;

    &::before {
        font-size: 14px;
        position: absolute;
        content: '\f054';
        font-family: 'Font Awesome 6 Pro';
        font-weight: bold;
        color: $secondary;
        right: 5px;
        top: 17px;
        transition: right .3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        color: $secondary;
        text-decoration: none;
        
        &::before {
            right: 0px;
        }
    }

    // variations
    &--white {
        color: $white;
    
        &::before {
            color: $white;
        }
    
        &:hover,
        &:active,
        &:focus {
            color: $white;
        }
    }
}

.c-button-link-nav {
    cursor: pointer;
    display: block;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: $text;
    padding-right: 28px;
    position: relative;

    &::before {
        font-size: 16px;
        position: absolute;
        content: '\f178';
        font-family: 'Font Awesome 6 Pro';
        font-weight: bold;
        color: $primary;
        right: 5px;
        // top: 2px;
        transition: right .3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        color: $text_highlight;
        text-decoration: none;
        
        &::before {
            right: 0px;
        }

    }
}

.c-button-icon {
    border: none;
    cursor: pointer;
    display         : flex;
    width           : 40px;
    height          : 40px;
    color           : $white;
    border-radius   : 999px;
    background-color: $primary;
    align-items     : center;
    font-size       : 16px;
    justify-content : center;
    transition: all .3s ease;

    &:hover,
    &:active,
    &:focus {
        color: $primary;
        background-color: darken($color: $primary, $amount: 10%);
        text-decoration: none;
        transform: scale(1.05);
    }

    // Variations
    &--primary {
        background-color: $primary;
        color: $white;

        &:hover,
        &:active,
        &:focus {
            color: $white;
        }
    }

    &--remove {
        background-color: $muted;
    
        &:hover,
        &:active,
        &:focus {
            color: $white;
            background-color: $danger-color;
        }
    }

    &--big {
        font-size       : 21px;
        width: 60px;
        height: 60px;
    }
}


.c-big-link-category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__image {
        margin-bottom: 16px;
    }

    &__label {
        cursor: pointer;
        display: block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        color: $text;
        padding-right: 28px;
        position: relative;
        transition: all .3s ease;
    
        &::before {
            font-size: 16px;
            position: absolute;
            content: '\f178';
            font-family: 'Font Awesome 6 Pro';
            font-weight: bold;
            color: $primary;
            right: 5px;
            top: 2px;
            transition: right .3s ease;
        }
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;

        .c-big-link-category__label {
            color: $text_highlight;
            text-decoration: none;
            
            &::before {
                right: 0px;
            }
        }
    }
}

.c-big-link-news {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__image {
        margin-bottom: 16px;
    }

    &__label {
        @extend .t-h4;
        font-weight: 700;
        margin-bottom: 2px;
        transition: all .3s ease;
    }
    
    &__date {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        color: $muted;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        
        .c-big-link-news__label {
            color: $text_highlight;
        }
    }
}

.c-img-link {
    display: block;
    position: relative;

    &__bttn {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 30px);
        opacity: 0;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        
        .c-img-link__bttn {
            top: calc(50% - 30px);
            opacity: 1;
        }
    }
}

.c-video-link {
    display: block;
    position: relative;

    &__bttn {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
    }
}

.c-link-contact {
    color: $secondary;
    text-decoration: underline;
    margin-bottom: 6px;

    i,
    .fa-solid,
    .fa-regular,
    .fa-light,
    .fa-thin,
    .fa-brands,
    svg {
        margin-right: 4px;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: $text_highlight;
    }
}

.c-link-social {
    color: $secondary;
    transition: all .3s ease;
    font-size: 21px;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: $text_highlight;
    }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}