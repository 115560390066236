.address-box {
    border-radius  : $border-radius;
    border         : 1px solid $border;
    padding        : 30px;
    display        : flex;
    justify-content: space-between;
    align-items    : flex-start;
  
    >section {
      flex           : 1 0 0;
      display        : flex;
      flex-flow      : column;
      align-items    : flex-start;
      justify-content: flex-start;
  
      a {
        text-decoration: underline;
        color          : $primary;
        margin-bottom  : 6px;
  
        &:hover {
          color          : darken($color: $primary, $amount: 8%);
          text-decoration: none;
        }
  
        &.disabled {
          color          : $grey-dark;
          text-decoration: none;
  
          &:hover {
            cursor: not-allowed;
          }
        }
  
        &.no-click {
          color          : $grey-dark;
          text-decoration: none;
  
          &:hover {
            cursor: default;
          }
        }
  
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}