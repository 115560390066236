.c-timeline {
    &__year {
        color: $primary;
        font-size: 21px;
        margin: 0 0 16px 0;
    }

    &__title {
        font-size: 18px;
        color: $secondary;
        font-weight: 21px;
        margin: 0 0 8px 0;
    }

    &__section {
        border-radius: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 24px 0 40px 60px;
        text-align: start;
        position: relative;

        &::before {
            content: '';
            width: 2px;
            background-color: $bg_blue;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 32px;
        }

        &::after {
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background-color: $primary;
            position: absolute;
            left: 25px;
            top: 27px;
        }

        &.is-active {
            &::before {
                background-color: $secondary;
            }

            &::after {
                background-color: $secondary;
            }

            .c-timeline__title {
                font-size: 24px;
            }

            .t-cms {
                p {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}