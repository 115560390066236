.c-category-grid {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__section {

    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
    .c-category-grid {
        flex-direction: column;
     
        &__section {
            margin-bottom: 24px;
        }
    }
}