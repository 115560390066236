.uk-notification {
  min-width: 490px;
  z-index: 999999;

  .uk-notification-message {
    border          : 1px solid $primary;
    background-color: $white;
    min-width       : 490px;
    padding         : 25px 30px;
    font-weight     : 500;
    font-size       : 20px;
    line-height     : 28px;
    text-align      : left;
    color           : $primary;

    &-danger {
      background-color: $danger-color;
      color           : $white;
      border          : 1px solid $danger-color;
    }

    .uk-notification-close {
      top: 35px;
      right: 20px;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
  .uk-notification {
    min-width: calc(100vw - 24px);

    .uk-notification-message {
        min-width: calc(100vw - 24px);
        padding         : 15px 20px;
        font-size       : 16px;
        line-height     : 21px;
    }
  }
}