.c-accordion {
    > :nth-child(n+2) {
        margin-top: 0px;
    }

    > li {
        // border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        background-color: $white;
        margin: 0;
        padding: 30px;

        &:first-child {
            border-top: 1px solid $border;
        }
        
        &.uk-open {
            .uk-accordion-title {
                color: $text_highlight;
            }
        }
    }

    .uk-accordion-title {
        display: flex;
        align-items: center;
      
        &:focus {
            color: $text;
        }

        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: $text;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 700;

        &:hover,
        &:active,
        &:focus {
            color: $text_highlight;
        }
    }

    .uk-accordion-content {
        margin-top: 24px;
        margin-bottom: 12px;
    }
   
    &--arrow-right {
        .uk-accordion-title {
            padding-right: 30px;
            position: relative;
    
            &::after {
                position: absolute;
                right: 0;
                content:'\f078';
                font-family: 'Font Awesome 6 Pro';
                font-weight: 400;
                font-size: 16px;
                color: $border;
            }
        }

        .uk-open>.uk-accordion-title {
            &::after {
                content:'\f077';
                color: $primary;
            }
        }
    }

    mark {
        color: $primary !important;
        font-weight: 700 !important;
        background-color: transparent;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}