// ===========================================================
//  _  _      ___    _  _
// | || |    / _ \  | || |
// | || |_  | | | | | || |_
// |__   _| | | | | |__   _|
//   | |    | |_| |    | |
//   |_|     \___/     |_|
//
//   STYLING VOOR ONZE CUSTOM 404 PAGINA
//   MET ONDERSTEUNING VOOR ONZE GIF
// ===========================================================

$primary-color-404   : rgba(116,235,213,1);
$secondary-color-404 : rgba(172,182,229,1);

.wrapper-404 {
  position            : relative;
  width               : 100vw;
  height              : 100vh;
  background-position : center;
  background-size     : cover;

  .overlay-404 {
    display         : flex;
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    background      : linear-gradient(
                        135deg,
                        transparentize($primary-color-404, .33) 0%,
                        transparentize($secondary-color-404, .33) 100%
                      );
    align-items     : center;
    justify-content : center;

    .inner-404 {
      max-width        : 500px;
      padding          : 50px;
      border-radius    : 4px;
      background-color : $white;

      h1 {
        margin-bottom           : 15px;
        background              : linear-gradient(
                                     135deg,
                                     transparentize($primary-color-404, .33) 0%,
                                     transparentize($secondary-color-404, .33) 100%
                                   );
        text-transform          : uppercase;
        font-size               : 56px;
        font-weight             : 800;
        background-clip         : text;
        -webkit-text-fill-color : transparent;
      }

      h2 {
        margin-top  : 0;
        font-size   : 24px;
        font-weight : 400;
      }

      p {
        margin-bottom : 0;

        a {
          color : $primary-color-404;

          &:hover {
            color : darken($primary-color-404, 15%);
          }
        }
      }
    }
  }
}
