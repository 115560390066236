.cart-dropdown {
    width           : 480px;
    padding         : 24px 30px 30px;
    background-color: $white;
    border          : 1px solid $border;
    border-radius   : $border-radius;
  
    h6 {
      font-weight  : bold;
      font-size    : 18px;
      color        : $black;
      margin-bottom: 11px;
    }
  
    &__row {
      display              : grid;
      grid-template-columns: 1fr 5fr;
      gap                  : 30px 30px;
      width                : 100%;
      align-content        : flex-start;
      border-bottom        : 1px solid $border;
      padding              : 14px 0px;
    }
  
    &__image {
      background-repeat  : no-repeat;
      background-position: center center;
      background-color   : $secondary;
    }
  
    &__header {
      display        : flex;
      justify-content: space-between;
      align-items    : flex-start;
      margin-bottom  : 15px;
    }
  
    &__title,
    &__price {
      font-weight : bold;
      font-size   : 16px;
      line-height : 24px;
      color       : $black;
      margin-right: 20px;
    }
  
    &__price {
      flex: 1 0 auto;
      white-space: nowrap;
    }
  
    &__row:hover {
      text-decoration: none;
  
      .cart-dropdown__title,
      .cart-dropdown__price {
        color: $primary;
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}