.link-sidebar {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
        margin-bottom: 8px;

        a {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: $text;

            &:hover,
            &:active,
            &:focus {
                color: darken( $primary , 8% );
                text-decoration: underline;
            }

            &.uk-active,
            &.active {
                color: $primary;
                font-weight: bold;
                text-decoration: none;
                position: relative;

                &:hover,
                &:active,
                &:focus {
                    color: darken( $primary , 8% );
                }
            }


            
        }

        &.top-category {
            margin-bottom: 30px;

            font-family: $ff-stack;
            margin-bottom: 30px;
            font-weight: 700;  
            font-size: 24px;
            line-height: 28px;
            color: $text;

            a {
                font-family: $ff-stack;
                margin-bottom: 30px;
                font-weight: 700;  
                font-size: 24px;
                line-height: 28px;

                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}