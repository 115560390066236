.coupon {
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
    width          : 270px;
    position       : relative;
  
    &__input {
        width        : 100%;
        height       : 50px;
        border-radius: $border-radius 0 0 $border-radius;
        border       : 1px solid $border;
        border-right : 0;
        padding      : 0px 20px;
        font-family  : 'sofia-pro';
        font-size    : 16px;
        color        : $black;
        font-weight  : 300;
    
        &::placeholder {
            font-style: italic;
            color     : $grey-dark;
        }

        &:focus {
            border : 1px solid $primary;
            outline: none;
        }
    }
  
    &__submit {
        height          : 50px;
        width           : 50px;
        flex            : 0 0 50px;
        border-radius   : 0 $border-radius $border-radius 0;
        border          : 0;
        background-color: $primary;
        color           : $white;
        font-size       : 18px;
        cursor          : pointer;
    
        &:hover,
        &:active,
        &:focus {
            background-color: darken($color: $primary, $amount: 6%);
        }
    }
}
  

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}