.filter-checkbox {
    padding: 12px;
    margin: 8px 16px 8px 0;
    display: block;
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: $text;
    transition: all .3s ease;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: $text;
        border: 1px solid $primary;
        background-color: $bg_blue;
    }

    input[type="checkbox"] {
        display: none;
    }

    &.active {
        padding: 12px 12px 12px 36px;
        position: relative;
        color: $text;
        background-color: $bg_blue;
        border: 1px solid $primary;
        transition: all .3s ease;
        
        &::after {
            position: absolute;
            left: 12px;
            top: 14px;
            content:'\f00c';
            font-family: 'Font Awesome 6 Pro';
            font-weight: 700;
            font-size: 16px;
            color: $primary;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            border: 1px solid $primary;
            background-color: $bg_blue;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}