.c-explain-add {
    padding: 16px;
    width: 100%;
    background-color: $bg_blue;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
        margin: 0 8px;
        margin-top: 0;
    }

    &__placeholder-bttn {
        height: 40px;
        min-height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50px;
        background-color: $secondary;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease;
        font-size: 16px;

        // &:hover,
        // &:active,
        // &:focus {
        // 	text-decoration: none;
        // 	background-color: darken($color: $secondary, $amount: 10%);
        // 	transform: scale(1.01);
        // }
    }

    &__nav-bttn {
        display: flex;
        align-items: center;

        span {
            width: 24px;
            height: 24px;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary;
            margin-right: 6px;
            transition: all .3s ease;

            i,
            .fa-solid,
            .fa-regular,
            .fa-light,
            .fa-thin,
            .fa-brands,
            svg {
                font-size: 14px;
                color: $white;
            }
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;

            span {
                background-color: darken($color: $primary, $amount: 10%);
            }
        }
    }
}

.c-explain-add-detail {
    padding: 8px;
    font-size: 14px;
    p, span {
        font-size: 14px;
    }
    .circlebttn {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}