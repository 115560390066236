.c-product {
	cursor: pointer;
	width: 100%;
	border-radius: 20px;
	box-shadow: $shadow;
	background-color: $white;
	padding: 24px;
	border: none;

    display        : flex;
    flex-flow      : column;
    justify-content: space-between;
    align-items    : flex-start;
    height         : 100%;
	position: relative;
    
    &__header {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: start;
    }

    &__image {
    }

	&__line {
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 16px;
		color: $primary;
	}

    &__title {
		word-break: keep-all;
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		color: $secondary;
		margin-top: 0;
    }

	&__nr {
		margin-top: 4px;
		font-size: 14px;
		color: $muted;
	}
	  
    // &__like {
	// 	position: absolute;
	// 	bottom: 20px;
	// 	right: 20px;
	// 	color           : $primary;
	// 	cursor          : pointer;
	// 	background-color: $white;
	// 	border-radius   : 3px;
	// 	border          : 0;
	// 	padding         : 6px;
	
	// 	>.normal {
	// 		display: block;
	// 	}
	
	// 	>.active {
	// 		display: none;
	// 	}
	
	// 	&:hover,
	// 	&--liked {
	// 		>.normal {
	// 		display: none;
	// 		}
	
	// 		>.active {
	// 		display: block;
	// 		}
	// 	}
    // }

	&__add {
		height: 40px;
		width: 40px;
		border-radius: 50px;
		background-color: $secondary;
		color: $white;
		position: absolute;
		right: 24px;
		bottom: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .3s ease;
		font-size: 16px;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: darken($color: $secondary, $amount: 10%);
			transform: scale(1.01);
		}
	}

	&__remove {
		height: 40px;
		width: 40px;
		border-radius: 50px;
		background-color: $muted;
		color: $white;
		position: absolute;
		right: 24px;
		bottom: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .3s ease;
		font-size: 16px;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: $danger-color;
			transform: scale(1.01);
		}
	}

    &:hover,
    &:active,
    &:focus {
		text-decoration: none;
	
		.c-product__title {
			color: $primary;
			word-break: keep-all;
			white-space: normal;
		}
    }
}

.c-product-detail {
	&__nr {
		font-size: 18px;
		color: $muted;
		font-weight: normal;
	}
	&__line {
		font-size: 18px;
		line-height: 21px;
		color: $primary;
		font-weight: normal;
	}
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}