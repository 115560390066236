.default-dropdown-nav {
    padding         : 0;
    background-color: $white;
    border          : 1px solid $border;
    box-shadow      : 0 2px 8px rgba(0, 0, 0, 0.08);
  
    .uk-dropdown-nav {
        li {
            border-bottom: 1px solid $border;
    
            &:last-of-type {
                border-bottom: 0;
            }
    
            a {
                padding  : 10px 20px;
                font-size: 16px;
                color    : $black;
        
                &:hover {
                    background-color: $secondary;
                    color           : $primary;
                }
            }
    
            &.uk-active {
                a {
                    background-color: $primary;
                    color           : $white;
                    font-weight     : bold;
                }
            }
        }
    }
}
  


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}