.checkout-footer {
    padding         : 0;
    background-color: $secondary;

    .checkout-footer__inner {
        padding         : 60px 60px 30px 60px;
    }

    .checkout-footer__nav {
        display        : flex;
        justify-content: center;
        align-items    : center;
        margin-top     : 40px;

        li {
            margin: 0px 12px;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
    .checkout-footer {
        .checkout-footer__inner {
            padding: 20px;
        }
  
        .checkout-footer__nav {
            flex-flow: column;
        }
    }
}