.cookie-banner{
    box-shadow: 0px 3px 15px rgba($black, 0.2);
    z-index: 10;

    p{
        font-size: 14px;
        line-height: 125%;

        a{
            color: $cookie-link-color;
            text-decoration: underline;

            &:hover{
                color: darken($cookie-link-color, 15);
            }
        }
    }

    .button-wrap{
        min-width: 30%;
    }

    .button{
        margin: 2px;
        margin-left: 12px;
        padding: 10px;
        text-align: center;
        vertical-align: middle;
    }

    .uk-button-primary {
        background-color: $cookie-button-bgcolor;
        color           : $cookie-button-color;

        &:hover{
            background-color: darken($cookie-button-bgcolor, 5);
        }
    }

    #cookie_form{
        align-items: center;
        background-color: rgba(0,0,0,0.2);
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        padding: 24px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9;

        .form-url{
            display: none;
        }

        .cookie-banner-popup{
            background: #ffffff;
            box-shadow: 0px 3px 15px rgba($black, 0.2);
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 48px);
            max-width: 860px;
            width: 100%;

            &__bottom{
                border-top: 1px solid #dddddd;
                padding: 10px 20px;
            }

            &__top{
            overflow: auto;
                padding: 20px;
                flex: 1;
            }
        }
    }
}

@media only screen and (max-width: 961px){
    .cookie-banner{
        text-align: center;
        p{
            font-size: 12px;
            text-align: center !important;
        }

        .button{
            font-size: 12px;
        }
    }
}