.edit__page__button {
  z-index      : 9999;
  display      : flex;
  position     : fixed;
  bottom       : 30px;
  right        : 30px;
  width        : 60px;
  height       : 60px;
  color        : $white;
  border-radius: 999px;
  background   : linear-gradient(135deg,
  $primary-color-404 0%,
  $secondary-color-404 100%);
  align-items    : center;
  justify-content: center;
  transition     : all 350ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color    : $white;
    transform: scale(1.15);
  }
}