.uk-pagination {
    li {
		padding-left: 16px;
	
		>* {
			width          : 50px;
			height         : 50px;
			display        : flex;
			justify-content: center;
			align-items    : center;
			border         : none;
			border-radius  : $border-radius;
			padding: 0;
			font-weight: 700;
			font-size  : 16px;
			line-height: 30px;
			color      : $secondary;
		}
	
		>a {
			&:hover {
				color           : $primary;
			}
		}
	
		&.uk-active {
			>* {
				color: $primary;
			}
		}
    }

	&--prev {
		background-color: $secondary;
		transition: all .3s ease;
		
		i,
		.fa-solid,
		.fa-regular,
		.fa-light,
		.fa-thin,
		.fa-brands,
		svg {
			color: $white;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: darken($color: $secondary, $amount: 10%);
		}
	}

	&--next {
		background-color: $secondary;
		transition: all .3s ease;
		
		i,
		.fa-solid,
		.fa-regular,
		.fa-light,
		.fa-thin,
		.fa-brands,
		svg {
			color: $white;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: darken($color: $secondary, $amount: 10%);
		}
	}
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
  .uk-pagination {
    li {
      > * {
        width: 35px;
        height: 35px;
      }
    }
  }
}