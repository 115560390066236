.c-download-link {
	width: 100%;
    border: none;
    background-color: $white;
    cursor: pointer;
	border-radius: 20px;
	box-shadow: $shadow;
    padding: 0;
    overflow: hidden;

    &__image {
        display: block;
        aspect-ratio: 5 / 4;
        background-size: cover;
    }

    &__footer {
        padding: 24px 80px 24px 24px;
        display: flex;
        justify-content: space-between;
        position: relative;
		transition: all .3s ease;
    }

    &__title {
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		color: $secondary;
		margin-top: 0;
        text-align: start;
    }
    
	&__file-size {
        margin-top: 4px;
		font-size: 14px;
		color: $muted;
        text-align: start;
	}

	&__bttn {
		height: 40px;
		min-height: 40px;
		width: 40px;
		min-width: 40px;
		border-radius: 50px;
		background-color: $secondary;
		color: $white;
		position: absolute;
		top: 24px;
		right: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .3s ease;
		font-size: 16px;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: darken($color: $secondary, $amount: 10%);
			transform: scale(1.01);
		}
	}

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;

		.c-download-link__title {
			color: $primary;
		}
	}
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}