@charset "UTF-8";
/* Fonts
-------------------------------------------------- */
/* Colors
-------------------------------------------------- */
/* Border radius
-------------------------------------------------- */
/* Shadows
-------------------------------------------------- */
/* Mixin's
-------------------------------------------------- */
/* Functions
-------------------------------------------------- */
/* iframe videos
-------------------------------------------------- */
.vimeo-iframe, .youtube-iframe, .other-iframe {
  padding-bottom: 56.67%;
  position: relative;
  width: 100%;
}

.video-iframe {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100% !important;
}

.full-left-image,
.full-right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-left-image {
  right: 50%;
  left: 0;
}

.full-right-image {
  right: 0;
  left: 50%;
}

.image-block {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.image-block.square {
  padding-top: 100%;
}
.image-block.long {
  padding-top: 133%;
}
.image-block--half {
  padding-top: 56%;
}
.image-block.contain {
  background-size: contain;
}

/* Modals
-------------------------------------------------- */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(231, 248, 248, 0.95);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow-y: scroll;
}
.modal--big {
  align-items: flex-start;
}
.modal__box {
  width: 100%;
  max-width: 1050px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
  padding: 45px 60px;
}
.modal__box--image {
  position: relative;
}
.modal__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 66.66%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.modal__content {
  padding-left: calc(33.33% + 50px);
}

/* Category blocks
-------------------------------------------------- */
.block-link-category-wrapper {
  text-align: center;
}
.block-link-category-wrapper span {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}
.block-link-category-wrapper:hover {
  text-decoration: none;
}
.block-link-category-wrapper:hover span {
  color: #61A6C7;
  text-decoration: none !important;
}
.block-link-category-wrapper:hover .block-link-category {
  background-color: #61A6C7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-link-category-wrapper:hover .block-image-link-category {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.block-link-category {
  position: relative;
  background-color: #23376C;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.block-link-category span {
  position: absolute;
  left: 25px;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #222222;
  transition: color 0.3s ease;
}
.block-link-category:hover {
  background-color: #61A6C7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-link-category:hover span {
  color: #ffffff;
}

.block-image-link-category {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}
.block-image-link-category .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.3s ease;
}
.block-image-link-category span {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 25px;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  transition: color 0.3s ease;
}
.block-image-link-category:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-image-link-category:hover .overlay {
  background-color: rgba(97, 166, 199, 0.7);
}

/* General
-------------------------------------------------- */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-bg {
  width: 100vw;
  height: 100vh;
  z-index: -9999999;
  background-image: url("/dist/assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper {
  position: relative;
}
.lazyload-wrapper img {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/* Product detail page
-------------------------------------------------- */
.cart-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Backgrounds
-------------------------------------------------- */
.l-bg--blue {
  background: #E2F5FC;
}
.l-bg--blue-video {
  background: rgba(226, 245, 252, 0.5);
}
.l-bg--blue-dark {
  background: #23376C;
}
.l-bg--grey {
  background: #FAFAFA;
}
.l-bg--home {
  background-repeat: no-repeat;
  background-position: right bottom 40px;
  background-size: 15%;
}
.l-bg--picture-top {
  position: relative;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 35%;
  height: 100%;
  overflow: hidden;
}
.l-bg--picture-top .wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  height: 100%;
  width: 30%;
  transform: translateY(-50%);
}
.l-bg--picture-top .wrapper > div {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.l-bg--picture-top .wrapper-video {
  position: absolute;
  right: 0;
  top: 50%;
  height: 100%;
  width: 100%;
  z-index: -1;
  transform: translateY(-50%);
}

@media (max-width: 960px) {
  br {
    display: none;
  }

  .l-bg--home {
    padding-bottom: 150px;
    background-size: 250px;
    background-position: right bottom 24px;
  }
  .l-bg--picture-bttm {
    padding-bottom: 150px;
    background-size: 250px;
    background-position: right bottom 24px;
  }
  .l-bg--picture-top {
    padding-bottom: 150px;
    background-size: 250px;
    background-position: right bottom 24px;
  }
}
/*========================================================
=                       Typography                        =
=========================================================*/
.t-display {
  font-weight: bold;
  font-size: 80px;
  line-height: 92px;
  color: #23376C;
}

h1,
.t-h1,
.uk-h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: #23376C;
}

h2,
.t-h2,
.uk-h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 38.4px;
  color: #23376C;
}

h3,
.t-h3,
.uk-h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  color: #23376C;
}

h4,
.t-h4,
.top-banner--title,
.c-big-link-news__label,
.uk-h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #23376C;
}

.t-highlight {
  color: #4193BA;
}

h1:hover, h1:active, h1:focus, .t-h1:hover, .t-h1:active, .t-h1:focus, .uk-h1:hover, .uk-h1:active, .uk-h1:focus,
h2:hover,
h2:active,
h2:focus, .t-h2:hover, .t-h2:active, .t-h2:focus, .uk-h2:hover, .uk-h2:active, .uk-h2:focus,
h3:hover,
h3:active,
h3:focus, .t-h3:hover, .t-h3:active, .t-h3:focus, .uk-h3:hover, .uk-h3:active, .uk-h3:focus,
h4:hover,
h4:active,
h4:focus, .t-h4:hover, .top-banner--title:hover, .c-big-link-news__label:hover, .t-h4:active, .top-banner--title:active, .c-big-link-news__label:active, .t-h4:focus, .top-banner--title:focus, .c-big-link-news__label:focus, .uk-h4:hover, .uk-h4:active, .uk-h4:focus,
h5:hover,
h5:active,
h5:focus, .t-h5:hover, .t-h5:active, .t-h5:focus, .uk-h5:hover, .uk-h5:active, .uk-h5:focus,
p:hover,
p:active,
p:focus, .cms:hover, .cms:active, .cms:focus, .text:hover, .text:active, .text:focus, .t-cms:hover, .t-cms:active, .t-cms:focus, .t-text:hover, .t-text:active, .t-text:focus {
  text-decoration: none;
}

p,
.cms,
.t-cms,
.text,
.t-text {
  font-size: 18px;
  line-height: 27px;
  color: #28394F;
}
p em,
.cms em,
.t-cms em,
.text em,
.t-text em {
  color: #28394F;
}
p a,
.cms a,
.t-cms a,
.text a,
.t-text a {
  color: #4193BA;
  text-decoration: underline;
}
p a:hover, p a:active, p a:focus,
.cms a:hover,
.cms a:active,
.cms a:focus,
.t-cms a:hover,
.t-cms a:active,
.t-cms a:focus,
.text a:hover,
.text a:active,
.text a:focus,
.t-text a:hover,
.t-text a:active,
.t-text a:focus {
  color: #367b9c;
}
p table,
.cms table,
.t-cms table,
.text table,
.t-text table {
  border: 1px solid #D3D6E0;
  border-collapse: collapse;
}
p table th, p table td,
.cms table th,
.cms table td,
.t-cms table th,
.t-cms table td,
.text table th,
.text table td,
.t-text table th,
.t-text table td {
  padding: 8px 15px;
}
p strong,
.cms strong,
.t-cms strong,
.text strong,
.t-text strong {
  font-weight: 700;
  color: #4193BA;
}
p--big,
.cms--big,
.t-cms--big,
.text--big,
.t-text--big {
  font-size: 18px;
  line-height: 27px;
}
p--big p,
.cms--big p,
.t-cms--big p,
.text--big p,
.t-text--big p {
  font-size: 18px;
  line-height: 27px;
}
p--small,
.cms--small,
.t-cms--small,
.text--small,
.t-text--small {
  font-size: 14px;
  line-height: 21px;
}
p--small p,
.cms--small p,
.t-cms--small p,
.text--small p,
.t-text--small p {
  font-size: 14px;
  line-height: 21px;
}
p--tiny,
.cms--tiny,
.t-cms--tiny,
.text--tiny,
.t-text--tiny {
  font-size: 11px;
}
p--tiny p,
.cms--tiny p,
.t-cms--tiny p,
.text--tiny p,
.t-text--tiny p {
  font-size: 11px;
}
p--white,
.cms--white,
.t-cms--white,
.text--white,
.t-text--white {
  color: #ffffff;
}
p--white p,
.cms--white p,
.t-cms--white p,
.text--white p,
.t-text--white p {
  color: #ffffff;
}
p--grey,
.cms--grey,
.t-cms--grey,
.text--grey,
.t-text--grey {
  color: #D3D6E0;
}
p--grey p,
.cms--grey p,
.t-cms--grey p,
.text--grey p,
.t-text--grey p {
  color: #D3D6E0;
}
p--grey-dark,
.cms--grey-dark,
.t-cms--grey-dark,
.text--grey-dark,
.t-text--grey-dark {
  color: #666666;
}
p--grey-dark p,
.cms--grey-dark p,
.t-cms--grey-dark p,
.text--grey-dark p,
.t-text--grey-dark p {
  color: #666666;
}
p--black,
.cms--black,
.t-cms--black,
.text--black,
.t-text--black {
  color: #222222;
}
p--black p,
.cms--black p,
.t-cms--black p,
.text--black p,
.t-text--black p {
  color: #222222;
}
p--primary,
.cms--primary,
.t-cms--primary,
.text--primary,
.t-text--primary {
  color: #61A6C7;
}
p--primary p,
.cms--primary p,
.t-cms--primary p,
.text--primary p,
.t-text--primary p {
  color: #61A6C7;
}
p--success,
.cms--success,
.t-cms--success,
.text--success,
.t-text--success {
  color: #1eaf3a;
}
p--success p,
.cms--success p,
.t-cms--success p,
.text--success p,
.t-text--success p {
  color: #1eaf3a;
}
p--warning,
.cms--warning,
.t-cms--warning,
.text--warning,
.t-text--warning {
  color: #ff6601;
}
p--warning p,
.cms--warning p,
.t-cms--warning p,
.text--warning p,
.t-text--warning p {
  color: #ff6601;
}
p--danger,
.cms--danger,
.t-cms--danger,
.text--danger,
.t-text--danger {
  color: #D60727;
}
p--danger p,
.cms--danger p,
.t-cms--danger p,
.text--danger p,
.t-text--danger p {
  color: #D60727;
}
p--center,
.cms--center,
.t-cms--center,
.text--center,
.t-text--center {
  text-align: center;
}
p--center h1, p--center .t-h1, p--center .uk-h1,
p--center h2, p--center .t-h2, p--center .uk-h2,
p--center h3, p--center .t-h3, p--center .uk-h3,
p--center h4, p--center .t-h4, p--center .top-banner--title, p--center .c-big-link-news__label, p--center .uk-h4,
p--center h5, p--center .t-h5, p--center .uk-h5,
p--center p, p--center .cms, p--center .text, p--center .t-cms, p--center .t-text,
.cms--center h1,
.cms--center .t-h1,
.cms--center .uk-h1,
.cms--center h2,
.cms--center .t-h2,
.cms--center .uk-h2,
.cms--center h3,
.cms--center .t-h3,
.cms--center .uk-h3,
.cms--center h4,
.cms--center .t-h4,
.cms--center .top-banner--title,
.cms--center .c-big-link-news__label,
.cms--center .uk-h4,
.cms--center h5,
.cms--center .t-h5,
.cms--center .uk-h5,
.cms--center p,
.cms--center .cms,
.cms--center .text,
.cms--center .t-cms,
.cms--center .t-text,
.t-cms--center h1,
.t-cms--center .t-h1,
.t-cms--center .uk-h1,
.t-cms--center h2,
.t-cms--center .t-h2,
.t-cms--center .uk-h2,
.t-cms--center h3,
.t-cms--center .t-h3,
.t-cms--center .uk-h3,
.t-cms--center h4,
.t-cms--center .t-h4,
.t-cms--center .top-banner--title,
.t-cms--center .c-big-link-news__label,
.t-cms--center .uk-h4,
.t-cms--center h5,
.t-cms--center .t-h5,
.t-cms--center .uk-h5,
.t-cms--center p,
.t-cms--center .cms,
.t-cms--center .text,
.t-cms--center .t-cms,
.t-cms--center .t-text,
.text--center h1,
.text--center .t-h1,
.text--center .uk-h1,
.text--center h2,
.text--center .t-h2,
.text--center .uk-h2,
.text--center h3,
.text--center .t-h3,
.text--center .uk-h3,
.text--center h4,
.text--center .t-h4,
.text--center .top-banner--title,
.text--center .c-big-link-news__label,
.text--center .uk-h4,
.text--center h5,
.text--center .t-h5,
.text--center .uk-h5,
.text--center p,
.text--center .cms,
.text--center .text,
.text--center .t-cms,
.text--center .t-text,
.t-text--center h1,
.t-text--center .t-h1,
.t-text--center .uk-h1,
.t-text--center h2,
.t-text--center .t-h2,
.t-text--center .uk-h2,
.t-text--center h3,
.t-text--center .t-h3,
.t-text--center .uk-h3,
.t-text--center h4,
.t-text--center .t-h4,
.t-text--center .top-banner--title,
.t-text--center .c-big-link-news__label,
.t-text--center .uk-h4,
.t-text--center h5,
.t-text--center .t-h5,
.t-text--center .uk-h5,
.t-text--center p,
.t-text--center .cms,
.t-text--center .text,
.t-text--center .t-cms,
.t-text--center .t-text {
  text-align: center;
}
p--right,
.cms--right,
.t-cms--right,
.text--right,
.t-text--right {
  text-align: right;
}
p--right h1, p--right .t-h1, p--right .uk-h1,
p--right h2, p--right .t-h2, p--right .uk-h2,
p--right h3, p--right .t-h3, p--right .uk-h3,
p--right h4, p--right .t-h4, p--right .top-banner--title, p--right .c-big-link-news__label, p--right .uk-h4,
p--right h5, p--right .t-h5, p--right .uk-h5,
p--right p, p--right .cms, p--right .text, p--right .t-cms, p--right .t-text,
.cms--right h1,
.cms--right .t-h1,
.cms--right .uk-h1,
.cms--right h2,
.cms--right .t-h2,
.cms--right .uk-h2,
.cms--right h3,
.cms--right .t-h3,
.cms--right .uk-h3,
.cms--right h4,
.cms--right .t-h4,
.cms--right .top-banner--title,
.cms--right .c-big-link-news__label,
.cms--right .uk-h4,
.cms--right h5,
.cms--right .t-h5,
.cms--right .uk-h5,
.cms--right p,
.cms--right .cms,
.cms--right .text,
.cms--right .t-cms,
.cms--right .t-text,
.t-cms--right h1,
.t-cms--right .t-h1,
.t-cms--right .uk-h1,
.t-cms--right h2,
.t-cms--right .t-h2,
.t-cms--right .uk-h2,
.t-cms--right h3,
.t-cms--right .t-h3,
.t-cms--right .uk-h3,
.t-cms--right h4,
.t-cms--right .t-h4,
.t-cms--right .top-banner--title,
.t-cms--right .c-big-link-news__label,
.t-cms--right .uk-h4,
.t-cms--right h5,
.t-cms--right .t-h5,
.t-cms--right .uk-h5,
.t-cms--right p,
.t-cms--right .cms,
.t-cms--right .text,
.t-cms--right .t-cms,
.t-cms--right .t-text,
.text--right h1,
.text--right .t-h1,
.text--right .uk-h1,
.text--right h2,
.text--right .t-h2,
.text--right .uk-h2,
.text--right h3,
.text--right .t-h3,
.text--right .uk-h3,
.text--right h4,
.text--right .t-h4,
.text--right .top-banner--title,
.text--right .c-big-link-news__label,
.text--right .uk-h4,
.text--right h5,
.text--right .t-h5,
.text--right .uk-h5,
.text--right p,
.text--right .cms,
.text--right .text,
.text--right .t-cms,
.text--right .t-text,
.t-text--right h1,
.t-text--right .t-h1,
.t-text--right .uk-h1,
.t-text--right h2,
.t-text--right .t-h2,
.t-text--right .uk-h2,
.t-text--right h3,
.t-text--right .t-h3,
.t-text--right .uk-h3,
.t-text--right h4,
.t-text--right .t-h4,
.t-text--right .top-banner--title,
.t-text--right .c-big-link-news__label,
.t-text--right .uk-h4,
.t-text--right h5,
.t-text--right .t-h5,
.t-text--right .uk-h5,
.t-text--right p,
.t-text--right .cms,
.t-text--right .text,
.t-text--right .t-cms,
.t-text--right .t-text {
  text-align: right;
}

p {
  margin-bottom: 0;
}
p + p {
  margin-top: 24px;
}

.no-hover:hover {
  text-decoration: none;
}

.input-errors p {
  color: #D60727;
  font-size: 14px;
  margin-top: 5px;
  text-transform: uppercase;
  font-weight: 700;
}

sub, sup {
  font-size: 100%;
}

address {
  font-size: 16px;
  line-height: 24px;
  color: #28394F;
}
address a {
  color: #61A6C7;
  text-decoration: underline;
}
address a:hover, address a:active, address a:focus {
  color: #4395bc;
}

.t-date {
  margin-top: 10px;
  margin-bottom: 32px;
  font-size: 18px;
  color: #909AB5;
}

ol, ul {
  padding-left: 20px;
}
ol li, ul li {
  font-size: 16px;
  line-height: 24px;
  color: #28394F;
}
ol li::marker, ul li::marker {
  color: #61A6C7;
  font-size: 20px;
}

@media (max-width: 960px) {
  .t-display {
    font-size: 60px;
    line-height: 68px;
  }

  h1,
.t-h1,
.uk-h1 {
    font-size: 32px;
    line-height: 38.4px;
  }

  h2,
.t-h2,
.uk-h2 {
    font-size: 24px;
    line-height: 28.8px;
  }

  h3,
.t-h3,
.uk-h3 {
    font-size: 18px;
    line-height: 27px;
  }

  h4,
.t-h4,
.top-banner--title,
.c-big-link-news__label,
.uk-h4 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 640px) {
  .t-display {
    font-size: 40px;
    line-height: 45px;
  }
}
.cs-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.cs-wrapper .cs-inner {
  max-width: 500px;
  padding-right: 32px;
  padding-left: 32px;
}
.cs-wrapper .cs-inner .cs-card {
  background-color: #ffffff;
}
.cs-wrapper .cs-title {
  color: #222222;
  font-size: 24px;
}
.cs-wrapper .cs-text {
  color: rgba(34, 34, 34, 0.75);
  font-family: sans-serif;
  font-size: 16px;
  line-height: 28px;
}
.cs-wrapper .cs-socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cs-wrapper .cs-socials a {
  margin: 0px 4px;
  color: rgba(34, 34, 34, 0.65);
  font-size: 18px;
}
.cs-wrapper .cs-socials a:hover {
  color: #222222;
  text-decoration: none;
}

.wrapper-404 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
}
.wrapper-404 .overlay-404 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, rgba(116, 235, 213, 0.67) 0%, rgba(172, 182, 229, 0.67) 100%);
  align-items: center;
  justify-content: center;
}
.wrapper-404 .overlay-404 .inner-404 {
  max-width: 500px;
  padding: 50px;
  border-radius: 4px;
  background-color: #ffffff;
}
.wrapper-404 .overlay-404 .inner-404 h1 {
  margin-bottom: 15px;
  background: linear-gradient(135deg, rgba(116, 235, 213, 0.67) 0%, rgba(172, 182, 229, 0.67) 100%);
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 800;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wrapper-404 .overlay-404 .inner-404 h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 400;
}
.wrapper-404 .overlay-404 .inner-404 p {
  margin-bottom: 0;
}
.wrapper-404 .overlay-404 .inner-404 p a {
  color: rgb(116, 235, 213);
}
.wrapper-404 .overlay-404 .inner-404 p a:hover {
  color: #31e1c1;
}

.edit__page__button {
  z-index: 9999;
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  color: #ffffff;
  border-radius: 999px;
  background: linear-gradient(135deg, rgb(116, 235, 213) 0%, rgb(172, 182, 229) 100%);
  align-items: center;
  justify-content: center;
  transition: all 350ms cubic-bezier(0.77, 0, 0.175, 1);
}
.edit__page__button:hover, .edit__page__button:active, .edit__page__button:focus {
  text-decoration: none;
  color: #ffffff;
  transform: scale(1.15);
}

.cookie-banner {
  box-shadow: 0px 3px 15px rgba(34, 34, 34, 0.2);
  z-index: 10;
}
.cookie-banner p {
  font-size: 14px;
  line-height: 125%;
}
.cookie-banner p a {
  color: #222222;
  text-decoration: underline;
}
.cookie-banner p a:hover {
  color: black;
}
.cookie-banner .button-wrap {
  min-width: 30%;
}
.cookie-banner .button {
  margin: 2px;
  margin-left: 12px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.cookie-banner .uk-button-primary {
  background-color: #61A6C7;
  color: #ffffff;
}
.cookie-banner .uk-button-primary:hover {
  background-color: #4e9bc0;
}
.cookie-banner #cookie_form {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 24px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.cookie-banner #cookie_form .form-url {
  display: none;
}
.cookie-banner #cookie_form .cookie-banner-popup {
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(34, 34, 34, 0.2);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 48px);
  max-width: 860px;
  width: 100%;
}
.cookie-banner #cookie_form .cookie-banner-popup__bottom {
  border-top: 1px solid #dddddd;
  padding: 10px 20px;
}
.cookie-banner #cookie_form .cookie-banner-popup__top {
  overflow: auto;
  padding: 20px;
  flex: 1;
}

@media only screen and (max-width: 961px) {
  .cookie-banner {
    text-align: center;
  }
  .cookie-banner p {
    font-size: 12px;
    text-align: center !important;
  }
  .cookie-banner .button {
    font-size: 12px;
  }
}
/*========================================================
=                       Utilities                        =
=========================================================*/
/* positioning & alignment
-------------------------------------------------- */
.push-down,
.flex-grow {
  flex-grow: 1;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.pull-right--mini {
  margin-right: -1px;
}

.pull-bottom--mini {
  margin-bottom: -1px;
}

.h-p-rel {
  position: relative;
}

.h-p-abs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.h-p-center {
  margin: auto;
  display: block;
}

.h-wr-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.object-fit {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

/* blend modes
-------------------------------------------------- */
.blend--multiply {
  mix-blend-mode: multiply;
}

/* Spacing
-------------------------------------------------- */
.margin-top-verthus {
  margin-top: 30px;
}

.h-mt--0 {
  margin-top: 0px;
}

.h-mr--0 {
  margin-right: 0px;
}

.h-mb--0 {
  margin-bottom: 0px;
}

.h-ml--0 {
  margin-left: 0px;
}

.h-mt--5 {
  margin-top: 5px;
}

.h-mr--5 {
  margin-right: 5px;
}

.h-mb--5 {
  margin-bottom: 5px;
}

.h-ml--5 {
  margin-left: 5px;
}

.h-mt--10 {
  margin-top: 10px;
}

.h-mr--10 {
  margin-right: 10px;
}

.h-mb--10 {
  margin-bottom: 10px;
}

.h-ml--10 {
  margin-left: 10px;
}

.h-mt--15 {
  margin-top: 15px;
}

.h-mr--15 {
  margin-right: 15px;
}

.h-mb--15 {
  margin-bottom: 15px;
}

.h-ml--15 {
  margin-left: 15px;
}

.h-mt--20 {
  margin-top: 20px;
}

.h-mr--20 {
  margin-right: 20px;
}

.h-mb--20 {
  margin-bottom: 20px;
}

.h-ml--20 {
  margin-left: 20px;
}

.h-mt--25 {
  margin-top: 25px;
}

.h-mr--25 {
  margin-right: 25px;
}

.h-mb--25 {
  margin-bottom: 25px;
}

.h-ml--25 {
  margin-left: 25px;
}

.h-mt--30 {
  margin-top: 30px;
}

.h-mr--30 {
  margin-right: 30px;
}

.h-mb--30 {
  margin-bottom: 30px;
}

.h-ml--30 {
  margin-left: 30px;
}

.h-mt--35 {
  margin-top: 35px;
}

.h-mr--35 {
  margin-right: 35px;
}

.h-mb--35 {
  margin-bottom: 35px;
}

.h-ml--35 {
  margin-left: 35px;
}

.h-mt--40 {
  margin-top: 40px;
}

.h-mr--40 {
  margin-right: 40px;
}

.h-mb--40 {
  margin-bottom: 40px;
}

.h-ml--40 {
  margin-left: 40px;
}

.h-mt--45 {
  margin-top: 45px;
}

.h-mr--45 {
  margin-right: 45px;
}

.h-mb--45 {
  margin-bottom: 45px;
}

.h-ml--45 {
  margin-left: 45px;
}

.h-mt--50 {
  margin-top: 50px;
}

.h-mr--50 {
  margin-right: 50px;
}

.h-mb--50 {
  margin-bottom: 50px;
}

.h-ml--50 {
  margin-left: 50px;
}

.h-mt--55 {
  margin-top: 55px;
}

.h-mr--55 {
  margin-right: 55px;
}

.h-mb--55 {
  margin-bottom: 55px;
}

.h-ml--55 {
  margin-left: 55px;
}

.h-mt--60 {
  margin-top: 60px;
}

.h-mr--60 {
  margin-right: 60px;
}

.h-mb--60 {
  margin-bottom: 60px;
}

.h-ml--60 {
  margin-left: 60px;
}

.h-mt--65 {
  margin-top: 65px;
}

.h-mr--65 {
  margin-right: 65px;
}

.h-mb--65 {
  margin-bottom: 65px;
}

.h-ml--65 {
  margin-left: 65px;
}

.h-mt--70 {
  margin-top: 70px;
}

.h-mr--70 {
  margin-right: 70px;
}

.h-mb--70 {
  margin-bottom: 70px;
}

.h-ml--70 {
  margin-left: 70px;
}

.h-mt--75 {
  margin-top: 75px;
}

.h-mr--75 {
  margin-right: 75px;
}

.h-mb--75 {
  margin-bottom: 75px;
}

.h-ml--75 {
  margin-left: 75px;
}

.h-mt--80 {
  margin-top: 80px;
}

.h-mr--80 {
  margin-right: 80px;
}

.h-mb--80 {
  margin-bottom: 80px;
}

.h-ml--80 {
  margin-left: 80px;
}

.h-mt--85 {
  margin-top: 85px;
}

.h-mr--85 {
  margin-right: 85px;
}

.h-mb--85 {
  margin-bottom: 85px;
}

.h-ml--85 {
  margin-left: 85px;
}

.h-mt--90 {
  margin-top: 90px;
}

.h-mr--90 {
  margin-right: 90px;
}

.h-mb--90 {
  margin-bottom: 90px;
}

.h-ml--90 {
  margin-left: 90px;
}

.h-mt--95 {
  margin-top: 95px;
}

.h-mr--95 {
  margin-right: 95px;
}

.h-mb--95 {
  margin-bottom: 95px;
}

.h-ml--95 {
  margin-left: 95px;
}

.h-mt--100 {
  margin-top: 100px;
}

.h-mr--100 {
  margin-right: 100px;
}

.h-mb--100 {
  margin-bottom: 100px;
}

.h-ml--100 {
  margin-left: 100px;
}

.h-mt--105 {
  margin-top: 105px;
}

.h-mr--105 {
  margin-right: 105px;
}

.h-mb--105 {
  margin-bottom: 105px;
}

.h-ml--105 {
  margin-left: 105px;
}

.h-mt--110 {
  margin-top: 110px;
}

.h-mr--110 {
  margin-right: 110px;
}

.h-mb--110 {
  margin-bottom: 110px;
}

.h-ml--110 {
  margin-left: 110px;
}

.h-mt--115 {
  margin-top: 115px;
}

.h-mr--115 {
  margin-right: 115px;
}

.h-mb--115 {
  margin-bottom: 115px;
}

.h-ml--115 {
  margin-left: 115px;
}

.h-mt--120 {
  margin-top: 120px;
}

.h-mr--120 {
  margin-right: 120px;
}

.h-mb--120 {
  margin-bottom: 120px;
}

.h-ml--120 {
  margin-left: 120px;
}

.h-mt--125 {
  margin-top: 125px;
}

.h-mr--125 {
  margin-right: 125px;
}

.h-mb--125 {
  margin-bottom: 125px;
}

.h-ml--125 {
  margin-left: 125px;
}

.h-mt--130 {
  margin-top: 130px;
}

.h-mr--130 {
  margin-right: 130px;
}

.h-mb--130 {
  margin-bottom: 130px;
}

.h-ml--130 {
  margin-left: 130px;
}

.h-mt--135 {
  margin-top: 135px;
}

.h-mr--135 {
  margin-right: 135px;
}

.h-mb--135 {
  margin-bottom: 135px;
}

.h-ml--135 {
  margin-left: 135px;
}

.h-mt--140 {
  margin-top: 140px;
}

.h-mr--140 {
  margin-right: 140px;
}

.h-mb--140 {
  margin-bottom: 140px;
}

.h-ml--140 {
  margin-left: 140px;
}

.h-mt--145 {
  margin-top: 145px;
}

.h-mr--145 {
  margin-right: 145px;
}

.h-mb--145 {
  margin-bottom: 145px;
}

.h-ml--145 {
  margin-left: 145px;
}

.h-mt--150 {
  margin-top: 150px;
}

.h-mr--150 {
  margin-right: 150px;
}

.h-mb--150 {
  margin-bottom: 150px;
}

.h-ml--150 {
  margin-left: 150px;
}

.h-mt--155 {
  margin-top: 155px;
}

.h-mr--155 {
  margin-right: 155px;
}

.h-mb--155 {
  margin-bottom: 155px;
}

.h-ml--155 {
  margin-left: 155px;
}

.h-mt--160 {
  margin-top: 160px;
}

.h-mr--160 {
  margin-right: 160px;
}

.h-mb--160 {
  margin-bottom: 160px;
}

.h-ml--160 {
  margin-left: 160px;
}

.h-mt--165 {
  margin-top: 165px;
}

.h-mr--165 {
  margin-right: 165px;
}

.h-mb--165 {
  margin-bottom: 165px;
}

.h-ml--165 {
  margin-left: 165px;
}

.h-mt--170 {
  margin-top: 170px;
}

.h-mr--170 {
  margin-right: 170px;
}

.h-mb--170 {
  margin-bottom: 170px;
}

.h-ml--170 {
  margin-left: 170px;
}

.h-mt--175 {
  margin-top: 175px;
}

.h-mr--175 {
  margin-right: 175px;
}

.h-mb--175 {
  margin-bottom: 175px;
}

.h-ml--175 {
  margin-left: 175px;
}

.h-mt--180 {
  margin-top: 180px;
}

.h-mr--180 {
  margin-right: 180px;
}

.h-mb--180 {
  margin-bottom: 180px;
}

.h-ml--180 {
  margin-left: 180px;
}

.h-mt--185 {
  margin-top: 185px;
}

.h-mr--185 {
  margin-right: 185px;
}

.h-mb--185 {
  margin-bottom: 185px;
}

.h-ml--185 {
  margin-left: 185px;
}

.h-mt--190 {
  margin-top: 190px;
}

.h-mr--190 {
  margin-right: 190px;
}

.h-mb--190 {
  margin-bottom: 190px;
}

.h-ml--190 {
  margin-left: 190px;
}

.h-mt--195 {
  margin-top: 195px;
}

.h-mr--195 {
  margin-right: 195px;
}

.h-mb--195 {
  margin-bottom: 195px;
}

.h-ml--195 {
  margin-left: 195px;
}

.h-mt--200 {
  margin-top: 200px;
}

.h-mr--200 {
  margin-right: 200px;
}

.h-mb--200 {
  margin-bottom: 200px;
}

.h-ml--200 {
  margin-left: 200px;
}

.h-pt--0 {
  padding-top: 0px;
}

.h-pr--0 {
  padding-right: 0px;
}

.h-pb--0 {
  padding-bottom: 0px;
}

.h-pl--0 {
  padding-left: 0px;
}

.h-pt--5 {
  padding-top: 5px;
}

.h-pr--5 {
  padding-right: 5px;
}

.h-pb--5 {
  padding-bottom: 5px;
}

.h-pl--5 {
  padding-left: 5px;
}

.h-pt--10 {
  padding-top: 10px;
}

.h-pr--10 {
  padding-right: 10px;
}

.h-pb--10 {
  padding-bottom: 10px;
}

.h-pl--10 {
  padding-left: 10px;
}

.h-pt--15 {
  padding-top: 15px;
}

.h-pr--15 {
  padding-right: 15px;
}

.h-pb--15 {
  padding-bottom: 15px;
}

.h-pl--15 {
  padding-left: 15px;
}

.h-pt--20 {
  padding-top: 20px;
}

.h-pr--20 {
  padding-right: 20px;
}

.h-pb--20 {
  padding-bottom: 20px;
}

.h-pl--20 {
  padding-left: 20px;
}

.h-pt--25 {
  padding-top: 25px;
}

.h-pr--25 {
  padding-right: 25px;
}

.h-pb--25 {
  padding-bottom: 25px;
}

.h-pl--25 {
  padding-left: 25px;
}

.h-pt--30 {
  padding-top: 30px;
}

.h-pr--30 {
  padding-right: 30px;
}

.h-pb--30 {
  padding-bottom: 30px;
}

.h-pl--30 {
  padding-left: 30px;
}

.h-pt--35 {
  padding-top: 35px;
}

.h-pr--35 {
  padding-right: 35px;
}

.h-pb--35 {
  padding-bottom: 35px;
}

.h-pl--35 {
  padding-left: 35px;
}

.h-pt--40 {
  padding-top: 40px;
}

.h-pr--40 {
  padding-right: 40px;
}

.h-pb--40 {
  padding-bottom: 40px;
}

.h-pl--40 {
  padding-left: 40px;
}

.h-pt--45 {
  padding-top: 45px;
}

.h-pr--45 {
  padding-right: 45px;
}

.h-pb--45 {
  padding-bottom: 45px;
}

.h-pl--45 {
  padding-left: 45px;
}

.h-pt--50 {
  padding-top: 50px;
}

.h-pr--50 {
  padding-right: 50px;
}

.h-pb--50 {
  padding-bottom: 50px;
}

.h-pl--50 {
  padding-left: 50px;
}

.h-pt--55 {
  padding-top: 55px;
}

.h-pr--55 {
  padding-right: 55px;
}

.h-pb--55 {
  padding-bottom: 55px;
}

.h-pl--55 {
  padding-left: 55px;
}

.h-pt--60 {
  padding-top: 60px;
}

.h-pr--60 {
  padding-right: 60px;
}

.h-pb--60 {
  padding-bottom: 60px;
}

.h-pl--60 {
  padding-left: 60px;
}

.h-pt--65 {
  padding-top: 65px;
}

.h-pr--65 {
  padding-right: 65px;
}

.h-pb--65 {
  padding-bottom: 65px;
}

.h-pl--65 {
  padding-left: 65px;
}

.h-pt--70 {
  padding-top: 70px;
}

.h-pr--70 {
  padding-right: 70px;
}

.h-pb--70 {
  padding-bottom: 70px;
}

.h-pl--70 {
  padding-left: 70px;
}

.h-pt--75 {
  padding-top: 75px;
}

.h-pr--75 {
  padding-right: 75px;
}

.h-pb--75 {
  padding-bottom: 75px;
}

.h-pl--75 {
  padding-left: 75px;
}

.h-pt--80 {
  padding-top: 80px;
}

.h-pr--80 {
  padding-right: 80px;
}

.h-pb--80 {
  padding-bottom: 80px;
}

.h-pl--80 {
  padding-left: 80px;
}

.h-pt--85 {
  padding-top: 85px;
}

.h-pr--85 {
  padding-right: 85px;
}

.h-pb--85 {
  padding-bottom: 85px;
}

.h-pl--85 {
  padding-left: 85px;
}

.h-pt--90 {
  padding-top: 90px;
}

.h-pr--90 {
  padding-right: 90px;
}

.h-pb--90 {
  padding-bottom: 90px;
}

.h-pl--90 {
  padding-left: 90px;
}

.h-pt--95 {
  padding-top: 95px;
}

.h-pr--95 {
  padding-right: 95px;
}

.h-pb--95 {
  padding-bottom: 95px;
}

.h-pl--95 {
  padding-left: 95px;
}

.h-pt--100 {
  padding-top: 100px;
}

.h-pr--100 {
  padding-right: 100px;
}

.h-pb--100 {
  padding-bottom: 100px;
}

.h-pl--100 {
  padding-left: 100px;
}

.h-pt--105 {
  padding-top: 105px;
}

.h-pr--105 {
  padding-right: 105px;
}

.h-pb--105 {
  padding-bottom: 105px;
}

.h-pl--105 {
  padding-left: 105px;
}

.h-pt--110 {
  padding-top: 110px;
}

.h-pr--110 {
  padding-right: 110px;
}

.h-pb--110 {
  padding-bottom: 110px;
}

.h-pl--110 {
  padding-left: 110px;
}

.h-pt--115 {
  padding-top: 115px;
}

.h-pr--115 {
  padding-right: 115px;
}

.h-pb--115 {
  padding-bottom: 115px;
}

.h-pl--115 {
  padding-left: 115px;
}

.h-pt--120 {
  padding-top: 120px;
}

.h-pr--120 {
  padding-right: 120px;
}

.h-pb--120 {
  padding-bottom: 120px;
}

.h-pl--120 {
  padding-left: 120px;
}

.h-pt--125 {
  padding-top: 125px;
}

.h-pr--125 {
  padding-right: 125px;
}

.h-pb--125 {
  padding-bottom: 125px;
}

.h-pl--125 {
  padding-left: 125px;
}

.h-pt--130 {
  padding-top: 130px;
}

.h-pr--130 {
  padding-right: 130px;
}

.h-pb--130 {
  padding-bottom: 130px;
}

.h-pl--130 {
  padding-left: 130px;
}

.h-pt--135 {
  padding-top: 135px;
}

.h-pr--135 {
  padding-right: 135px;
}

.h-pb--135 {
  padding-bottom: 135px;
}

.h-pl--135 {
  padding-left: 135px;
}

.h-pt--140 {
  padding-top: 140px;
}

.h-pr--140 {
  padding-right: 140px;
}

.h-pb--140 {
  padding-bottom: 140px;
}

.h-pl--140 {
  padding-left: 140px;
}

.h-pt--145 {
  padding-top: 145px;
}

.h-pr--145 {
  padding-right: 145px;
}

.h-pb--145 {
  padding-bottom: 145px;
}

.h-pl--145 {
  padding-left: 145px;
}

.h-pt--150 {
  padding-top: 150px;
}

.h-pr--150 {
  padding-right: 150px;
}

.h-pb--150 {
  padding-bottom: 150px;
}

.h-pl--150 {
  padding-left: 150px;
}

.h-pt--155 {
  padding-top: 155px;
}

.h-pr--155 {
  padding-right: 155px;
}

.h-pb--155 {
  padding-bottom: 155px;
}

.h-pl--155 {
  padding-left: 155px;
}

.h-pt--160 {
  padding-top: 160px;
}

.h-pr--160 {
  padding-right: 160px;
}

.h-pb--160 {
  padding-bottom: 160px;
}

.h-pl--160 {
  padding-left: 160px;
}

.h-pt--165 {
  padding-top: 165px;
}

.h-pr--165 {
  padding-right: 165px;
}

.h-pb--165 {
  padding-bottom: 165px;
}

.h-pl--165 {
  padding-left: 165px;
}

.h-pt--170 {
  padding-top: 170px;
}

.h-pr--170 {
  padding-right: 170px;
}

.h-pb--170 {
  padding-bottom: 170px;
}

.h-pl--170 {
  padding-left: 170px;
}

.h-pt--175 {
  padding-top: 175px;
}

.h-pr--175 {
  padding-right: 175px;
}

.h-pb--175 {
  padding-bottom: 175px;
}

.h-pl--175 {
  padding-left: 175px;
}

.h-pt--180 {
  padding-top: 180px;
}

.h-pr--180 {
  padding-right: 180px;
}

.h-pb--180 {
  padding-bottom: 180px;
}

.h-pl--180 {
  padding-left: 180px;
}

.h-pt--185 {
  padding-top: 185px;
}

.h-pr--185 {
  padding-right: 185px;
}

.h-pb--185 {
  padding-bottom: 185px;
}

.h-pl--185 {
  padding-left: 185px;
}

.h-pt--190 {
  padding-top: 190px;
}

.h-pr--190 {
  padding-right: 190px;
}

.h-pb--190 {
  padding-bottom: 190px;
}

.h-pl--190 {
  padding-left: 190px;
}

.h-pt--195 {
  padding-top: 195px;
}

.h-pr--195 {
  padding-right: 195px;
}

.h-pb--195 {
  padding-bottom: 195px;
}

.h-pl--195 {
  padding-left: 195px;
}

.h-pt--200 {
  padding-top: 200px;
}

.h-pr--200 {
  padding-right: 200px;
}

.h-pb--200 {
  padding-bottom: 200px;
}

.h-pl--200 {
  padding-left: 200px;
}

/* Sizing
-------------------------------------------------- */
.h-w-15 {
  width: 15%;
}

.h-w-25 {
  width: 25%;
}

.h-w-50 {
  width: 50%;
}

.h-w-75 {
  width: 75%;
}

.h-w-100 {
  width: 100%;
}

.h-h-100 {
  height: 100%;
}

/* Border
-------------------------------------------------- */
.h-br-circle {
  border-radius: 99999px;
}

.h-br-top {
  border-radius: 25px 25px 0 0;
}

.h-br-25 {
  border-radius: 25px;
}

hr {
  border-top: 1px solid #D3D6E0;
}

/* Backgrounds
-------------------------------------------------- */
.bg--primary {
  background: #61A6C7;
}
.bg--secondary {
  background: #23376C;
}

/* Hover
-------------------------------------------------- */
.no-hover:hover {
  text-decoration: none;
}

@media (max-width: 960px) {
  .modal {
    padding: 60px 0;
  }
  .modal__box {
    max-width: 95vw;
    padding: 30px;
  }

  .modal--big .modal__box {
    width: 95%;
  }
  .modal--big .product-row {
    display: initial;
  }
  .modal--big .product-row .image-block {
    padding-top: 50%;
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 640px) {
  .uk-flex\@s {
    display: flex !important;
  }
}
@media (max-width: 640px) {
  .modal--big {
    padding: 40px 0;
  }
  .modal--big .modal--title {
    font-size: 2rem;
    max-width: 100%;
  }
  .modal--big .product-added-wrap {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
}
/*------------------------------------*\
  #COMPONENTS SHOP
\*------------------------------------*/
.active-filter {
  display: flex;
  align-items: baseline;
  padding: 8px 10px;
  border: 1px solid #D3D6E0;
  border-radius: 3px;
}
.active-filter span {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.active-filter i,
.active-filter svg {
  font-size: 12px;
  color: #666666;
  margin-left: 5px;
}
.active-filter:hover {
  cursor: pointer;
}
.active-filter:hover i,
.active-filter:hover svg {
  color: #D60727;
}

.add-to-cart .uk-offcanvas-bar {
  width: 500px;
  left: -500px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
  padding: 60px;
}
.add-to-cart.uk-open .uk-offcanvas-bar {
  left: 0;
}

@media (max-width: 640px) {
  .add-to-cart .uk-offcanvas-bar {
    padding: 25px;
    width: 100vw;
    left: -100vw;
  }
}
.address-box {
  border-radius: 50px;
  border: 1px solid #D3D6E0;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.address-box > section {
  flex: 1 0 0;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.address-box > section a {
  text-decoration: underline;
  color: #61A6C7;
  margin-bottom: 6px;
}
.address-box > section a:hover {
  color: #4395bc;
  text-decoration: none;
}
.address-box > section a.disabled {
  color: #666666;
  text-decoration: none;
}
.address-box > section a.disabled:hover {
  cursor: not-allowed;
}
.address-box > section a.no-click {
  color: #666666;
  text-decoration: none;
}
.address-box > section a.no-click:hover {
  cursor: default;
}
.address-box > section a:last-of-type {
  margin-bottom: 0;
}

.uk-breadcrumb li a {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.uk-breadcrumb li a:hover {
  color: #61A6C7;
}
.uk-breadcrumb li span {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: ">";
  color: #e0e0e0;
  margin: 0px 15px;
}

.uk-button-cart {
  background-color: #1eaf3a;
  color: #ffffff;
}
.uk-button-cart:hover {
  background-color: #188c2e;
}

.uk-button-back {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #61A6C7;
}
.uk-button-back:hover {
  text-decoration: none;
  color: #000;
}

.big-button {
  padding: 45px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #D3D6E0;
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
  color: #222222;
}
.big-button:hover {
  text-decoration: none;
  border: 1px solid #61A6C7;
  background-color: #23376C;
  color: #61A6C7;
}
.big-button.uk-active {
  background-color: #61A6C7;
  border: 1px solid #61A6C7;
  color: #ffffff;
}

.cart-dropdown {
  width: 480px;
  padding: 24px 30px 30px;
  background-color: #ffffff;
  border: 1px solid #D3D6E0;
  border-radius: 50px;
}
.cart-dropdown h6 {
  font-weight: bold;
  font-size: 18px;
  color: #222222;
  margin-bottom: 11px;
}
.cart-dropdown__row {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 30px 30px;
  width: 100%;
  align-content: flex-start;
  border-bottom: 1px solid #D3D6E0;
  padding: 14px 0px;
}
.cart-dropdown__image {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #23376C;
}
.cart-dropdown__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}
.cart-dropdown__title, .cart-dropdown__price {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-right: 20px;
}
.cart-dropdown__price {
  flex: 1 0 auto;
  white-space: nowrap;
}
.cart-dropdown__row:hover {
  text-decoration: none;
}
.cart-dropdown__row:hover .cart-dropdown__title,
.cart-dropdown__row:hover .cart-dropdown__price {
  color: #61A6C7;
}

.cart {
  overflow: hidden;
}
.cart__row {
  border-bottom: 1px solid #D3D6E0;
  padding: 15px 0;
  display: grid;
  grid-template-columns: 100px 3fr 1fr 1fr;
  grid-template-areas: "remove link amount price";
  column-gap: 10px;
  row-gap: 10px;
}
.cart__row:last-of-type {
  border-bottom: 0;
}
.cart__row .cga-remove {
  grid-area: remove;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart__row .cga-link {
  grid-area: link;
}
.cart__row .cga-amount {
  grid-area: amount;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cart__row .cga-price {
  grid-area: price;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.cart__remove {
  border: 0;
  background-color: transparent;
  padding: 0;
  font-size: 21px;
  color: #D3D6E0;
  cursor: pointer;
}
.cart__remove:hover {
  color: #D60727;
}
.cart__image {
  position: relative;
}
.cart__product-info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cart__product-info h1, .cart__product-info .uk-h1,
.cart__product-info h2, .cart__product-info .uk-h2,
.cart__product-info h3, .cart__product-info .uk-h3,
.cart__product-info h4, .cart__product-info .uk-h4,
.cart__product-info h5, .cart__product-info .uk-h5,
.cart__product-info p, .cart__product-info .cms, .cart__product-info .text {
  transition: all 0.3s ease;
  color: #28394F;
}
.cart__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cart__link:hover, .cart__link:active, .cart__link:focus {
  text-decoration: none;
}
.cart__link:hover .cart__product-info h1, .cart__link:hover .cart__product-info .uk-h1,
.cart__link:hover .cart__product-info h2, .cart__link:hover .cart__product-info .uk-h2,
.cart__link:hover .cart__product-info h3, .cart__link:hover .cart__product-info .uk-h3,
.cart__link:hover .cart__product-info h4, .cart__link:hover .cart__product-info .uk-h4,
.cart__link:hover .cart__product-info h5, .cart__link:hover .cart__product-info .uk-h5,
.cart__link:hover .cart__product-info p, .cart__link:hover .cart__product-info .cms, .cart__link:hover .cart__product-info .text, .cart__link:active .cart__product-info h1, .cart__link:active .cart__product-info .uk-h1,
.cart__link:active .cart__product-info h2, .cart__link:active .cart__product-info .uk-h2,
.cart__link:active .cart__product-info h3, .cart__link:active .cart__product-info .uk-h3,
.cart__link:active .cart__product-info h4, .cart__link:active .cart__product-info .uk-h4,
.cart__link:active .cart__product-info h5, .cart__link:active .cart__product-info .uk-h5,
.cart__link:active .cart__product-info p, .cart__link:active .cart__product-info .cms, .cart__link:active .cart__product-info .text, .cart__link:focus .cart__product-info h1, .cart__link:focus .cart__product-info .uk-h1,
.cart__link:focus .cart__product-info h2, .cart__link:focus .cart__product-info .uk-h2,
.cart__link:focus .cart__product-info h3, .cart__link:focus .cart__product-info .uk-h3,
.cart__link:focus .cart__product-info h4, .cart__link:focus .cart__product-info .uk-h4,
.cart__link:focus .cart__product-info h5, .cart__link:focus .cart__product-info .uk-h5,
.cart__link:focus .cart__product-info p, .cart__link:focus .cart__product-info .cms, .cart__link:focus .cart__product-info .text {
  color: #61A6C7;
}
.cart__link.no-hover:hover, .cart__link.no-hover:active, .cart__link.no-hover:focus {
  text-decoration: none;
}
.cart__link.no-hover:hover .cart__product-info h1, .cart__link.no-hover:hover .cart__product-info .uk-h1,
.cart__link.no-hover:hover .cart__product-info h2, .cart__link.no-hover:hover .cart__product-info .uk-h2,
.cart__link.no-hover:hover .cart__product-info h3, .cart__link.no-hover:hover .cart__product-info .uk-h3,
.cart__link.no-hover:hover .cart__product-info h4, .cart__link.no-hover:hover .cart__product-info .uk-h4,
.cart__link.no-hover:hover .cart__product-info h5, .cart__link.no-hover:hover .cart__product-info .uk-h5,
.cart__link.no-hover:hover .cart__product-info p, .cart__link.no-hover:hover .cart__product-info .cms, .cart__link.no-hover:hover .cart__product-info .text, .cart__link.no-hover:active .cart__product-info h1, .cart__link.no-hover:active .cart__product-info .uk-h1,
.cart__link.no-hover:active .cart__product-info h2, .cart__link.no-hover:active .cart__product-info .uk-h2,
.cart__link.no-hover:active .cart__product-info h3, .cart__link.no-hover:active .cart__product-info .uk-h3,
.cart__link.no-hover:active .cart__product-info h4, .cart__link.no-hover:active .cart__product-info .uk-h4,
.cart__link.no-hover:active .cart__product-info h5, .cart__link.no-hover:active .cart__product-info .uk-h5,
.cart__link.no-hover:active .cart__product-info p, .cart__link.no-hover:active .cart__product-info .cms, .cart__link.no-hover:active .cart__product-info .text, .cart__link.no-hover:focus .cart__product-info h1, .cart__link.no-hover:focus .cart__product-info .uk-h1,
.cart__link.no-hover:focus .cart__product-info h2, .cart__link.no-hover:focus .cart__product-info .uk-h2,
.cart__link.no-hover:focus .cart__product-info h3, .cart__link.no-hover:focus .cart__product-info .uk-h3,
.cart__link.no-hover:focus .cart__product-info h4, .cart__link.no-hover:focus .cart__product-info .uk-h4,
.cart__link.no-hover:focus .cart__product-info h5, .cart__link.no-hover:focus .cart__product-info .uk-h5,
.cart__link.no-hover:focus .cart__product-info p, .cart__link.no-hover:focus .cart__product-info .cms, .cart__link.no-hover:focus .cart__product-info .text {
  color: #28394F;
}
.cart__amount {
  height: 50px;
  width: 65px;
  border: 1px solid #D3D6E0;
  color: #222222;
  border-radius: 3px;
  font-family: myriad-pro, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #222222;
}
.cart__amount--danger {
  border: 1px solid #D60727;
  color: #D60727;
}
.cart__amount:focus {
  border: 1px solid #61A6C7;
  outline: none;
}
.cart__prices {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.cart__prices--current {
  margin: 0 10px 0 0;
  font-family: myriad-pro, sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #28394F;
}
.cart__prices--label {
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #28394F;
}
.cart__prices--old {
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #28394F;
  text-decoration: line-through;
}
.cart__footer {
  padding: 60px 0;
}
.cart__footer select {
  width: 270px;
}
.cart__totals {
  display: grid;
  grid-template-columns: 3.2fr 1.3fr;
  gap: 12px 30px;
}
.cart__totals__label {
  text-align: right;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.cart__totals__label.total {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #61A6C7;
}
.cart__totals__price {
  text-align: right;
  font-family: myriad-pro, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #28394F;
  margin: 0;
  white-space: nowrap;
}
.cart__totals__price.delivery-cost {
  color: #61A6C7;
}
.cart__totals__price.total {
  font-size: 32px;
  line-height: 38px;
  color: #61A6C7;
}

@media (max-width: 1200px) {
  .cart__totals {
    grid-template-columns: 2.5fr 2fr;
  }
}
@media (max-width: 960px) {
  .cart__row {
    grid-template-columns: 40px 3fr 1fr 1fr;
  }
  .cart__totals {
    grid-template-columns: 1fr 1fr;
  }
  .cart__totals h3 {
    padding-right: 10px;
  }
}
@media (max-width: 640px) {
  .cart__row {
    grid-template-columns: 40px 1fr 1fr;
    grid-template-areas: "remove link link" "remove amount price";
    row-gap: 20px;
  }
  .cart__footer {
    padding: 20px 0;
  }
  .cart__totals {
    grid-template-columns: 1.5fr 1fr;
    gap: 16px 30px;
  }
  .cart__totals h3 {
    padding-right: 0px;
  }
}
.check-list {
  display: flex;
  flex-flow: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
}
.check-list li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.check-list li i,
.check-list li svg {
  flex: 0 0 20px;
  margin-right: 10px;
}

.checkout-footer {
  padding: 0;
  background-color: #23376C;
}
.checkout-footer .checkout-footer__inner {
  padding: 60px 60px 30px 60px;
}
.checkout-footer .checkout-footer__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.checkout-footer .checkout-footer__nav li {
  margin: 0px 12px;
}

@media (max-width: 640px) {
  .checkout-footer .checkout-footer__inner {
    padding: 20px;
  }
  .checkout-footer .checkout-footer__nav {
    flex-flow: column;
  }
}
.checkout-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.checkout-nav li {
  margin-right: 30px;
}
.checkout-nav li a {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.checkout-nav li a img,
.checkout-nav li a svg {
  margin-bottom: 10px;
}
.checkout-nav li a:hover {
  text-decoration: none;
  color: #61A6C7;
}
.checkout-nav li:last-of-type {
  margin-right: 0;
}

.checkout-steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 10px;
  grid-template-areas: ". . . . .";
  border-radius: 3px;
  overflow: hidden;
  padding-right: 16px;
}
.checkout-steps .checkout-step {
  flex: 1 0 auto;
  background-color: #61A6C7;
  padding: 12px 0px 12px 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.checkout-steps .checkout-step:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #61A6C7;
  border-width: 24px;
  margin-top: -24px;
}
.checkout-steps .checkout-step:after {
  left: 0%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #ffffff;
  border-width: 24px;
  margin-top: -24px;
}
.checkout-steps .checkout-step.uk-active {
  color: #ffffff;
  background-color: #61A6C7;
}
.checkout-steps .checkout-step.uk-active:before {
  border-left-color: #61A6C7;
}
.checkout-steps .checkout-step.uk-past {
  color: #ffffff;
  background-color: #23376C;
}
.checkout-steps .checkout-step.uk-past:before {
  border-left-color: #23376C;
}
.checkout-steps .checkout-step:nth-child(1) {
  z-index: 5;
}
.checkout-steps .checkout-step:nth-child(1):after {
  display: none;
}
.checkout-steps .checkout-step:nth-child(2) {
  z-index: 4;
}
.checkout-steps .checkout-step:nth-child(3) {
  z-index: 3;
}
.checkout-steps .checkout-step:nth-child(4) {
  z-index: 2;
}
.checkout-steps .checkout-step:nth-child(5) {
  z-index: 1;
}
.checkout-steps .checkout-step:nth-child(5):before {
  display: none;
}

@media (max-width: 640px) {
  .checkout-steps {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: "." "." "." ".";
    grid-gap: 8px;
    padding-right: 0px;
  }
  .checkout-steps .checkout-step {
    justify-content: flex-start;
  }
  .checkout-steps .checkout-step:before {
    display: none;
  }
  .checkout-steps .checkout-step:after {
    display: none;
  }
}
.color-picker {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: 5px 5px;
}
.color-picker a {
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  background-color: var(--bg-color);
  border: 2px solid #D3D6E0;
}
.color-picker a:hover {
  border: 2px solid #23376C;
}
.color-picker a.active {
  border: 2px solid #61A6C7;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
}
.color-picker a.disabled {
  background: repeating-linear-gradient(45deg, var(--bg-color), var(--bg-color) 2px, #D3D6E0 2px, #D3D6E0 4px);
}
.color-picker a.disabled:hover {
  cursor: no-drop;
}
.color-picker--wide {
  grid-template-columns: repeat(16, 1fr);
}

@media (max-width: 640px) {
  .color-picker {
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
  }
}
.coupon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 270px;
  position: relative;
}
.coupon__input {
  width: 100%;
  height: 50px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #D3D6E0;
  border-right: 0;
  padding: 0px 20px;
  font-family: "sofia-pro";
  font-size: 16px;
  color: #222222;
  font-weight: 300;
}
.coupon__input::placeholder {
  font-style: italic;
  color: #666666;
}
.coupon__input:focus {
  border: 1px solid #61A6C7;
  outline: none;
}
.coupon__submit {
  height: 50px;
  width: 50px;
  flex: 0 0 50px;
  border-radius: 0 50px 50px 0;
  border: 0;
  background-color: #61A6C7;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}
.coupon__submit:hover, .coupon__submit:active, .coupon__submit:focus {
  background-color: #4a99bf;
}

.delivery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px 10px;
}
.delivery__existing {
  border: 1px solid #D3D6E0;
  background-color: transparent;
  border-radius: 50px;
  padding: 30px;
  text-align: left;
  cursor: pointer;
  font-family: myriad-pro, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.delivery__existing:hover {
  background-color: #23376C;
  border: 1px solid #61A6C7;
  color: #61A6C7 !important;
}
.delivery__existing.active {
  box-shadow: inset 0 0 0 2px #61A6C7;
  border: 1px solid #61A6C7;
  background-color: #23376C;
}
.delivery__new {
  position: relative;
  background-color: #23376C;
  border: 0;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: myriad-pro, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  cursor: pointer;
}
.delivery__new i,
.delivery__new svg {
  font-size: 14px;
}
.delivery__new.active {
  background-color: #61A6C7;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.delivery__new.active::after {
  display: block;
}
.delivery__new::after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #61A6C7;
  border-width: 10px;
  margin-left: -10px;
  display: none;
}
.delivery__new:hover {
  background-color: #61A6C7;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.delivery__new:hover::after {
  display: block;
}

#new-delivery, #new-billing {
  border: 1px solid #D3D6E0;
  margin-top: 30px;
  margin-left: 0;
  padding: 23px 30px 30px 15px;
}

@media (max-width: 960px) {
  .delivery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 640px) {
  .delivery {
    grid-template-columns: repeat(1, 1fr);
  }
  .delivery__new {
    height: 80px;
  }
}
.footer-bar {
  background-color: #61A6C7;
  padding: 40px 0;
}
.footer-bar * {
  color: #ffffff !important;
}
.footer-bar * p:last-child {
  margin-bottom: 0;
}

.image-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #61A6C7;
  color: #ffffff;
  padding: 6px 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  border-radius: 0 3px 0 0;
}
.image-banner--detail {
  font-size: 18px;
  line-height: 27px;
  padding: 16px 20px;
}

.info--circle {
  color: #61A6C7 !important;
  transform: rotate(360deg) !important;
  font-size: 16px !important;
}
.info--modal h2 {
  color: #61A6C7;
}

.link-sidebar {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.link-sidebar li {
  margin-bottom: 8px;
}
.link-sidebar li a {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #28394F;
}
.link-sidebar li a:hover, .link-sidebar li a:active, .link-sidebar li a:focus {
  color: #4395bc;
  text-decoration: underline;
}
.link-sidebar li a.uk-active, .link-sidebar li a.active {
  color: #61A6C7;
  font-weight: bold;
  text-decoration: none;
  position: relative;
}
.link-sidebar li a.uk-active:hover, .link-sidebar li a.uk-active:active, .link-sidebar li a.uk-active:focus, .link-sidebar li a.active:hover, .link-sidebar li a.active:active, .link-sidebar li a.active:focus {
  color: #4395bc;
}
.link-sidebar li.top-category {
  margin-bottom: 30px;
  font-family: myriad-pro, sans-serif;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #28394F;
}
.link-sidebar li.top-category a {
  font-family: myriad-pro, sans-serif;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.link-sidebar li.top-category a:hover, .link-sidebar li.top-category a:active, .link-sidebar li.top-category a:focus {
  text-decoration: none;
}

.uk-notification {
  min-width: 490px;
  z-index: 999999;
}
.uk-notification .uk-notification-message {
  border: 1px solid #61A6C7;
  background-color: #ffffff;
  min-width: 490px;
  padding: 25px 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  color: #61A6C7;
}
.uk-notification .uk-notification-message-danger {
  background-color: #D60727;
  color: #ffffff;
  border: 1px solid #D60727;
}
.uk-notification .uk-notification-message .uk-notification-close {
  top: 35px;
  right: 20px;
}

@media (max-width: 640px) {
  .uk-notification {
    min-width: calc(100vw - 24px);
  }
  .uk-notification .uk-notification-message {
    min-width: calc(100vw - 24px);
    padding: 15px 20px;
    font-size: 16px;
    line-height: 21px;
  }
}
.order {
  padding: 30px;
  border-radius: 3px;
  border: 1px solid #D3D6E0;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 30px 30px;
}
.order__right {
  display: grid;
  gap: 30px 30px;
  grid-template-columns: 1fr 1fr;
}
.order__item {
  display: grid;
  gap: 20px 20px;
  grid-template-columns: 1fr 1.5fr;
  align-items: start;
}
.order__item .image-block {
  background-size: contain;
}

.uk-pagination li {
  padding-left: 16px;
}
.uk-pagination li > * {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #23376C;
}
.uk-pagination li > a:hover {
  color: #61A6C7;
}
.uk-pagination li.uk-active > * {
  color: #61A6C7;
}
.uk-pagination--prev {
  background-color: #23376C;
  transition: all 0.3s ease;
}
.uk-pagination--prev i,
.uk-pagination--prev .fa-solid,
.uk-pagination--prev .fa-regular,
.uk-pagination--prev .fa-light,
.uk-pagination--prev .fa-thin,
.uk-pagination--prev .fa-brands,
.uk-pagination--prev svg {
  color: #ffffff;
}
.uk-pagination--prev:hover, .uk-pagination--prev:active, .uk-pagination--prev:focus {
  text-decoration: none;
  background-color: #172345;
}
.uk-pagination--next {
  background-color: #23376C;
  transition: all 0.3s ease;
}
.uk-pagination--next i,
.uk-pagination--next .fa-solid,
.uk-pagination--next .fa-regular,
.uk-pagination--next .fa-light,
.uk-pagination--next .fa-thin,
.uk-pagination--next .fa-brands,
.uk-pagination--next svg {
  color: #ffffff;
}
.uk-pagination--next:hover, .uk-pagination--next:active, .uk-pagination--next:focus {
  text-decoration: none;
  background-color: #172345;
}

@media (max-width: 640px) {
  .uk-pagination li > * {
    width: 35px;
    height: 35px;
  }
}
.payment-method {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.perk-bar {
  background-color: #61A6C7;
  padding: 15px 0;
}
.perk-bar * {
  color: #ffffff;
}

.c-product {
  cursor: pointer;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  padding: 24px;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  position: relative;
}
.c-product__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}
.c-product__line {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  color: #61A6C7;
}
.c-product__title {
  word-break: keep-all;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #23376C;
  margin-top: 0;
}
.c-product__nr {
  margin-top: 4px;
  font-size: 14px;
  color: #909AB5;
}
.c-product__add {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: #23376C;
  color: #ffffff;
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 16px;
}
.c-product__add:hover, .c-product__add:active, .c-product__add:focus {
  text-decoration: none;
  background-color: #172345;
  transform: scale(1.01);
}
.c-product__remove {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: #909AB5;
  color: #ffffff;
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 16px;
}
.c-product__remove:hover, .c-product__remove:active, .c-product__remove:focus {
  text-decoration: none;
  background-color: #D60727;
  transform: scale(1.01);
}
.c-product:hover, .c-product:active, .c-product:focus {
  text-decoration: none;
}
.c-product:hover .c-product__title, .c-product:active .c-product__title, .c-product:focus .c-product__title {
  color: #61A6C7;
  word-break: keep-all;
  white-space: normal;
}

.c-product-detail__nr {
  font-size: 18px;
  color: #909AB5;
  font-weight: normal;
}
.c-product-detail__line {
  font-size: 18px;
  line-height: 21px;
  color: #61A6C7;
  font-weight: normal;
}

.c-product-small {
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  padding: 16px;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
}
.c-product-small__middel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}
.c-product-small__image {
  margin-right: 16px;
  height: 50px;
  width: 50px;
}
.c-product-small__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #23376C;
  margin-top: 0;
}
.c-product-small__nr {
  margin-top: 4px;
  font-size: 14px;
  color: #909AB5;
}
.c-product-small__add {
  margin-left: 40px;
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50px;
  background-color: #23376C;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 16px;
}
.c-product-small__add:hover, .c-product-small__add:active, .c-product-small__add:focus {
  text-decoration: none;
  background-color: #172345;
  transform: scale(1.01);
}
.c-product-small__remove {
  margin-left: 40px;
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50px;
  background-color: #909AB5;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 16px;
}
.c-product-small__remove:hover, .c-product-small__remove:active, .c-product-small__remove:focus {
  text-decoration: none;
  background-color: #D60727;
  transform: scale(1.01);
}
.c-product-small:hover, .c-product-small:active, .c-product-small:focus {
  text-decoration: none;
}
.c-product-small:hover .c-product__title, .c-product-small:active .c-product__title, .c-product-small:focus .c-product__title {
  color: #61A6C7;
  word-break: keep-all;
  white-space: normal;
}

@media (max-width: 640px) {
  .c-product-small {
    padding: 12px;
  }
  .c-product-small__image {
    margin-right: 8px;
  }
  .c-product-small__title {
    font-size: 16px;
  }
  .c-product-small__add {
    margin-left: 16px;
  }
  .c-product-small__remove {
    margin-left: 16px;
  }
}
.product-gallery {
  border: 1px solid #D3D6E0;
}
.product-gallery__image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
}
.product-gallery__nav {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-template-areas: ". . . . .";
  margin-top: 30px;
}
.product-gallery__nav .product-gallery__nav--image {
  background-color: #ffffff;
  border: 1px solid #D3D6E0;
  padding: 10px 15px;
  height: 100px;
  cursor: pointer;
  transition: border 0.15s ease-in-out;
}
.product-gallery__nav .product-gallery__nav--image > img {
  max-height: 100%;
}
.product-gallery__nav .product-gallery__nav--image:hover {
  border: 3px solid #61A6C7;
}
.product-gallery__nav .product-gallery__nav--image.uk-active {
  border: 1px solid #61A6C7;
  border-radius: 0 !important;
}
.product-gallery__nav .product-gallery__nav--plus {
  border: 0;
  background-color: #61A6C7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 21px;
  cursor: pointer;
}
.product-gallery__nav .product-gallery__nav--plus:hover {
  background-color: #4797be;
}

@media (max-width: 640px) {
  .product-gallery__nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px 10px;
    grid-template-areas: ". . ." ". . .";
  }
}
@media all and (-ms-high-contrast: none) {
  .product-gallery__nav {
    display: -ms-grid;
    -ms-grid-columns: repeat(5, 1fr);
    -ms-grid-rows: 1fr;
  }
}
.product-row {
  display: grid;
  grid-template-columns: 1fr 2fr 0.75fr 1.5fr;
  gap: 30px 30px;
}
.product-row .image-block {
  background-size: contain;
}

@media (max-width: 960px) {
  .product-row {
    display: initial;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". ." ". .";
  }
  .product-row .image-block {
    padding-top: 50%;
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 640px) {
  .product-row {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-areas: "." "." "." ".";
    align-items: start;
  }
}
.products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D3D6E0;
  margin-top: 40px;
}
.products-header .custom-select {
  margin-bottom: -1px;
  border-radius: 50px 50px 0 0;
}

@media (max-width: 960px) {
  .products-header {
    flex-flow: column;
    align-items: stretch;
    border-bottom: 0;
  }
}
.search-bar {
  width: 100%;
  position: relative;
}
.search-bar__search {
  position: relative;
  width: 100%;
}
.search-bar__search input[type=text],
.search-bar__search input[type=search] {
  width: 100%;
  height: 50px;
  padding: 0 70px 0 20px;
  border-radius: 3px;
  border: 1px solid #D3D6E0;
  font-family: myriad-pro, sans-serif;
  font-size: 16px;
  color: #222222;
}
.search-bar__search input[type=text]::placeholder,
.search-bar__search input[type=search]::placeholder {
  color: #222222;
}
.search-bar__search input[type=text]:focus,
.search-bar__search input[type=search]:focus {
  color: #61A6C7;
  border: 1px solid #61A6C7;
  outline: none;
}
.search-bar__search button[type=submit] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  color: #222222;
  cursor: pointer;
}
.search-bar__search button[type=submit]:hover {
  color: #61A6C7;
}
.search-bar__search-results {
  position: absolute;
  top: 54px;
  background-color: #ffffff;
  border: 1px solid #D3D6E0;
  width: 100%;
  border-radius: 0 0 4px 4px;
  padding: 30px;
  margin: 0;
  list-style-type: none;
  transform: translateY(15px);
  opacity: 0;
  z-index: -9999;
  transition: transform 0.4s ease, opacity 0.2s ease;
}
.search-bar__search-results .loader {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 25px;
  color: #61A6C7;
}
.search-bar__search-results li a {
  display: block;
  margin-left: -30px;
  margin-right: -30px;
  padding: 3px 30px;
  font-weight: 300;
  font-size: 16px;
  color: #222222;
}
.search-bar__search-results li a mark {
  font-weight: bold;
  background: transparent;
}
.search-bar__search-results li a:hover {
  background-color: #23376C;
  text-decoration: none;
}
.search-bar__search-results li.title p {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 12px;
  color: #666666;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 15px;
}
.search-bar__search-results li:first-of-type {
  margin-top: 0;
}
.search-bar__search-results li:last-of-type {
  margin-bottom: 0;
}
.search-bar__search-results.active {
  transform: translateY(0);
  opacity: 1;
  z-index: 999;
}

.sidebar > li {
  border-bottom: 1px solid #D3D6E0;
  padding-bottom: 30px;
}
.sidebar > li:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}
.sidebar > li a.uk-accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #D3D6E0;
}
.sidebar > li a.uk-accordion-title span {
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: #222222;
}
.sidebar > li a.uk-accordion-title i,
.sidebar > li a.uk-accordion-title svg {
  font-size: 12px;
  color: #222222;
}
.sidebar > li.uk-open a.uk-accordion-title i,
.sidebar > li.uk-open a.uk-accordion-title svg {
  transform: rotate(180deg);
}
.sidebar__nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sidebar__nav li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar__nav li a .text {
  font-weight: 300;
  font-size: 16px;
  line-height: 28.8px;
  color: #222222;
}
.sidebar__nav li a .amount {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #666666;
}
.sidebar__nav li a:hover {
  text-decoration: none;
}
.sidebar__nav li a:hover .text {
  color: #61A6C7;
  font-weight: bold;
}
.sidebar__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar__price > p {
  flex: 0 1 auto;
}
.sidebar__price > input[type=number] {
  width: 70px;
  flex: 0 0 70px;
  height: 50px;
  border: 1px solid #D3D6E0;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: myriad-pro, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  /* Chrome, Safari, Edge, Opera */
  -moz-appearance: textfield;
}
.sidebar__price > input[type=number]::-webkit-outer-spin-button, .sidebar__price > input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sidebar__price > input[type=number]:focus {
  outline: none;
  border: 1px solid #61A6C7;
}
.sidebar__price > button[type=submit] {
  width: 50px;
  flex: 0 0 50px;
  height: 50px;
  cursor: pointer;
  background-color: #61A6C7;
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  font-size: 18px;
}
.sidebar__price > button[type=submit]:hover {
  background-color: #4395bc;
}

.size-picker .sizes {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.size-picker .sizes button.size {
  height: 30px;
  border: 1px solid #D3D6E0;
  margin: 0px 5px 5px 0;
  border-radius: 50px;
  background-color: transparent;
  cursor: pointer;
  font-family: myriad-pro, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
  transition: 0.15s ease-in-out;
}
.size-picker .sizes button.size:hover, .size-picker .sizes button.size.uk-active {
  background-color: #23376C;
  border: 1px solid #61A6C7;
  font-weight: bold;
}
.size-picker .sizes button.size.uk-active {
  font-weight: bold;
}

.uk-table-striped--alt tbody tr:nth-of-type(odd) {
  background-color: #23376C;
  color: #ffffff;
}
.uk-table-striped--alt tbody tr:nth-of-type(odd) * {
  color: #ffffff;
}
.uk-table-wysiwyg thead th {
  background-color: #222222;
  color: #ffffff;
}
.uk-table-wysiwyg--alt thead th {
  background-color: #61A6C7;
}
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background-color: #61A6C7;
}
.uk-table-hover > tr:hover *,
.uk-table-hover tbody tr:hover * {
  color: #ffffff;
  font-weight: bold;
}

/*------------------------------------*\
  #COMPONENTS CMS
\*------------------------------------*/
.c-accordion > :nth-child(n+2) {
  margin-top: 0px;
}
.c-accordion > li {
  border-bottom: 1px solid #D3D6E0;
  border-left: 1px solid #D3D6E0;
  border-right: 1px solid #D3D6E0;
  background-color: #ffffff;
  margin: 0;
  padding: 30px;
}
.c-accordion > li:first-child {
  border-top: 1px solid #D3D6E0;
}
.c-accordion > li.uk-open .uk-accordion-title {
  color: #4193BA;
}
.c-accordion .uk-accordion-title {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #28394F;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700;
}
.c-accordion .uk-accordion-title:focus {
  color: #28394F;
}
.c-accordion .uk-accordion-title:hover, .c-accordion .uk-accordion-title:active, .c-accordion .uk-accordion-title:focus {
  color: #4193BA;
}
.c-accordion .uk-accordion-content {
  margin-top: 24px;
  margin-bottom: 12px;
}
.c-accordion--arrow-right .uk-accordion-title {
  padding-right: 30px;
  position: relative;
}
.c-accordion--arrow-right .uk-accordion-title::after {
  position: absolute;
  right: 0;
  content: "\f078";
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  font-size: 16px;
  color: #D3D6E0;
}
.c-accordion--arrow-right .uk-open > .uk-accordion-title::after {
  content: "\f077";
  color: #61A6C7;
}
.c-accordion mark {
  color: #61A6C7 !important;
  font-weight: 700 !important;
  background-color: transparent;
}

.c-banner {
  min-height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.uk-link {
  position: relative;
  padding-right: 20px;
}
.uk-link span {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #61A6C7;
  transition: color 0.3s ease;
}
.uk-link i,
.uk-link svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.4s ease, color 0.3s ease;
  color: #61A6C7;
  font-size: 14px;
}
.uk-link:hover {
  text-decoration: none;
}
.uk-link:hover span,
.uk-link:hover i,
.uk-link:hover svg {
  color: #4a99bf;
}
.uk-link:hover i,
.uk-link:hover svg {
  transform: translateX(6px) translateY(-50%);
}
.uk-link--black span,
.uk-link--black i,
.uk-link--black svg {
  color: #222222;
}

.uk-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 30px;
  border-radius: 50px;
  color: #23376C;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.uk-button-primary,
.c-button-primary {
  cursor: pointer;
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 30px;
  border-radius: 50px;
  color: #ffffff;
  border: 2px solid #23376C;
  background-color: #23376C;
  transition: all 0.3s ease;
}
.uk-button-primary:hover, .uk-button-primary:active, .uk-button-primary:focus,
.c-button-primary:hover,
.c-button-primary:active,
.c-button-primary:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #172345;
  border: 2px solid #172345;
}
.uk-button-primary--white,
.c-button-primary--white {
  color: #23376C !important;
  border: 2px solid #ffffff;
  background-color: #ffffff;
}
.uk-button-primary--white:hover, .uk-button-primary--white:active, .uk-button-primary--white:focus,
.c-button-primary--white:hover,
.c-button-primary--white:active,
.c-button-primary--white:focus {
  color: #23376C;
  background-color: #ffffff;
  border: 2px solid #ffffff;
}

.uk-button-secondary,
.c-button-secondary {
  cursor: pointer;
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 30px;
  border-radius: 50px;
  color: #23376C;
  border: 2px solid #23376C;
  background-color: transparent;
  transition: all 0.3s ease;
}
.uk-button-secondary:hover, .uk-button-secondary:active, .uk-button-secondary:focus,
.c-button-secondary:hover,
.c-button-secondary:active,
.c-button-secondary:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #172345;
  border: 2px solid #172345;
}
.uk-button-secondary--white,
.c-button-secondary--white {
  color: #ffffff;
  border: 2px solid #ffffff;
}
.uk-button-secondary--white:hover, .uk-button-secondary--white:active, .uk-button-secondary--white:focus,
.c-button-secondary--white:hover,
.c-button-secondary--white:active,
.c-button-secondary--white:focus {
  color: #23376C;
  background-color: #ffffff;
  border: 2px solid #ffffff;
}

.c-button-tertiairy {
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #23376C;
  padding-right: 20px;
  padding: 16px 20px 16px 0;
  position: relative;
}
.c-button-tertiairy::before {
  font-size: 14px;
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  font-weight: bold;
  color: #23376C;
  right: 5px;
  top: 17px;
  transition: right 0.3s ease;
}
.c-button-tertiairy:hover, .c-button-tertiairy:active, .c-button-tertiairy:focus {
  color: #23376C;
  text-decoration: none;
}
.c-button-tertiairy:hover::before, .c-button-tertiairy:active::before, .c-button-tertiairy:focus::before {
  right: 0px;
}
.c-button-tertiairy--white {
  color: #ffffff;
}
.c-button-tertiairy--white::before {
  color: #ffffff;
}
.c-button-tertiairy--white:hover, .c-button-tertiairy--white:active, .c-button-tertiairy--white:focus {
  color: #ffffff;
}

.c-button-link-nav {
  cursor: pointer;
  display: block;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #28394F;
  padding-right: 28px;
  position: relative;
}
.c-button-link-nav::before {
  font-size: 16px;
  position: absolute;
  content: "\f178";
  font-family: "Font Awesome 6 Pro";
  font-weight: bold;
  color: #61A6C7;
  right: 5px;
  transition: right 0.3s ease;
}
.c-button-link-nav:hover, .c-button-link-nav:active, .c-button-link-nav:focus {
  color: #4193BA;
  text-decoration: none;
}
.c-button-link-nav:hover::before, .c-button-link-nav:active::before, .c-button-link-nav:focus::before {
  right: 0px;
}

.c-button-icon {
  border: none;
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  color: #ffffff;
  border-radius: 999px;
  background-color: #61A6C7;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  transition: all 0.3s ease;
}
.c-button-icon:hover, .c-button-icon:active, .c-button-icon:focus {
  color: #61A6C7;
  background-color: #408fb5;
  text-decoration: none;
  transform: scale(1.05);
}
.c-button-icon--primary {
  background-color: #61A6C7;
  color: #ffffff;
}
.c-button-icon--primary:hover, .c-button-icon--primary:active, .c-button-icon--primary:focus {
  color: #ffffff;
}
.c-button-icon--remove {
  background-color: #909AB5;
}
.c-button-icon--remove:hover, .c-button-icon--remove:active, .c-button-icon--remove:focus {
  color: #ffffff;
  background-color: #D60727;
}
.c-button-icon--big {
  font-size: 21px;
  width: 60px;
  height: 60px;
}

.c-big-link-category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.c-big-link-category__image {
  margin-bottom: 16px;
}
.c-big-link-category__label {
  cursor: pointer;
  display: block;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #28394F;
  padding-right: 28px;
  position: relative;
  transition: all 0.3s ease;
}
.c-big-link-category__label::before {
  font-size: 16px;
  position: absolute;
  content: "\f178";
  font-family: "Font Awesome 6 Pro";
  font-weight: bold;
  color: #61A6C7;
  right: 5px;
  top: 2px;
  transition: right 0.3s ease;
}
.c-big-link-category:hover, .c-big-link-category:active, .c-big-link-category:focus {
  text-decoration: none;
}
.c-big-link-category:hover .c-big-link-category__label, .c-big-link-category:active .c-big-link-category__label, .c-big-link-category:focus .c-big-link-category__label {
  color: #4193BA;
  text-decoration: none;
}
.c-big-link-category:hover .c-big-link-category__label::before, .c-big-link-category:active .c-big-link-category__label::before, .c-big-link-category:focus .c-big-link-category__label::before {
  right: 0px;
}

.c-big-link-news {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.c-big-link-news__image {
  margin-bottom: 16px;
}
.c-big-link-news__label {
  font-weight: 700;
  margin-bottom: 2px;
  transition: all 0.3s ease;
}
.c-big-link-news__date {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #909AB5;
}
.c-big-link-news:hover, .c-big-link-news:active, .c-big-link-news:focus {
  text-decoration: none;
}
.c-big-link-news:hover .c-big-link-news__label, .c-big-link-news:active .c-big-link-news__label, .c-big-link-news:focus .c-big-link-news__label {
  color: #4193BA;
}

.c-img-link {
  display: block;
  position: relative;
}
.c-img-link__bttn {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 30px);
  opacity: 0;
}
.c-img-link:hover, .c-img-link:active, .c-img-link:focus {
  text-decoration: none;
}
.c-img-link:hover .c-img-link__bttn, .c-img-link:active .c-img-link__bttn, .c-img-link:focus .c-img-link__bttn {
  top: calc(50% - 30px);
  opacity: 1;
}

.c-video-link {
  display: block;
  position: relative;
}
.c-video-link__bttn {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
}

.c-link-contact {
  color: #23376C;
  text-decoration: underline;
  margin-bottom: 6px;
}
.c-link-contact i,
.c-link-contact .fa-solid,
.c-link-contact .fa-regular,
.c-link-contact .fa-light,
.c-link-contact .fa-thin,
.c-link-contact .fa-brands,
.c-link-contact svg {
  margin-right: 4px;
}
.c-link-contact:hover, .c-link-contact:active, .c-link-contact:focus {
  text-decoration: none;
  color: #4193BA;
}

.c-link-social {
  color: #23376C;
  transition: all 0.3s ease;
  font-size: 21px;
}
.c-link-social:hover, .c-link-social:active, .c-link-social:focus {
  text-decoration: none;
  color: #4193BA;
}

.c-cta {
  margin-top: 60px;
  border-radius: 20px;
  background-size: cover;
  padding: 32px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.c-cta::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(35, 55, 108, 0.6039215686);
  border-radius: 20px;
}
.c-cta * {
  color: #ffffff !important;
  z-index: 100;
}
.c-cta__text {
  margin: 24px 0 32px 0;
}
.c-cta__link::before {
  color: #ffffff;
}
.c-cta--large {
  background-color: #23376C;
  padding: 60px;
}
.c-cta p, .c-cta a, .c-cta i {
  font-size: 18px;
}
.c-cta .t-h3 {
  font-size: 24px;
}

.c-category-grid {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .c-category-grid {
    flex-direction: column;
  }
  .c-category-grid__section {
    margin-bottom: 24px;
  }
}
.default-dropdown-nav {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #D3D6E0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.default-dropdown-nav .uk-dropdown-nav li {
  border-bottom: 1px solid #D3D6E0;
}
.default-dropdown-nav .uk-dropdown-nav li:last-of-type {
  border-bottom: 0;
}
.default-dropdown-nav .uk-dropdown-nav li a {
  padding: 10px 20px;
  font-size: 16px;
  color: #222222;
}
.default-dropdown-nav .uk-dropdown-nav li a:hover {
  background-color: #23376C;
  color: #61A6C7;
}
.default-dropdown-nav .uk-dropdown-nav li.uk-active a {
  background-color: #61A6C7;
  color: #ffffff;
  font-weight: bold;
}

.c-download-link {
  width: 100%;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  padding: 0;
  overflow: hidden;
}
.c-download-link__image {
  display: block;
  aspect-ratio: 5/4;
  background-size: cover;
}
.c-download-link__footer {
  padding: 24px 80px 24px 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: all 0.3s ease;
}
.c-download-link__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #23376C;
  margin-top: 0;
  text-align: start;
}
.c-download-link__file-size {
  margin-top: 4px;
  font-size: 14px;
  color: #909AB5;
  text-align: start;
}
.c-download-link__bttn {
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50px;
  background-color: #23376C;
  color: #ffffff;
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 16px;
}
.c-download-link__bttn:hover, .c-download-link__bttn:active, .c-download-link__bttn:focus {
  text-decoration: none;
  background-color: #172345;
  transform: scale(1.01);
}
.c-download-link:hover, .c-download-link:active, .c-download-link:focus {
  text-decoration: none;
}
.c-download-link:hover .c-download-link__title, .c-download-link:active .c-download-link__title, .c-download-link:focus .c-download-link__title {
  color: #61A6C7;
}

.c-explain-add {
  padding: 16px;
  width: 100%;
  background-color: #E2F5FC;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.c-explain-add p {
  margin: 0 8px;
  margin-top: 0;
}
.c-explain-add__placeholder-bttn {
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50px;
  background-color: #23376C;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 16px;
}
.c-explain-add__nav-bttn {
  display: flex;
  align-items: center;
}
.c-explain-add__nav-bttn span {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #61A6C7;
  margin-right: 6px;
  transition: all 0.3s ease;
}
.c-explain-add__nav-bttn span i,
.c-explain-add__nav-bttn span .fa-solid,
.c-explain-add__nav-bttn span .fa-regular,
.c-explain-add__nav-bttn span .fa-light,
.c-explain-add__nav-bttn span .fa-thin,
.c-explain-add__nav-bttn span .fa-brands,
.c-explain-add__nav-bttn span svg {
  font-size: 14px;
  color: #ffffff;
}
.c-explain-add__nav-bttn:hover, .c-explain-add__nav-bttn:active, .c-explain-add__nav-bttn:focus {
  text-decoration: none;
}
.c-explain-add__nav-bttn:hover span, .c-explain-add__nav-bttn:active span, .c-explain-add__nav-bttn:focus span {
  background-color: #408fb5;
}

.c-explain-add-detail {
  padding: 8px;
  font-size: 14px;
}
.c-explain-add-detail p, .c-explain-add-detail span {
  font-size: 14px;
}
.c-explain-add-detail .circlebttn {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.filter-checkbox {
  padding: 12px;
  margin: 8px 16px 8px 0;
  display: block;
  background-color: transparent;
  border: 1px solid #61A6C7;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #28394F;
  transition: all 0.3s ease;
  cursor: pointer;
}
.filter-checkbox:hover, .filter-checkbox:active, .filter-checkbox:focus {
  text-decoration: none;
  color: #28394F;
  border: 1px solid #61A6C7;
  background-color: #E2F5FC;
}
.filter-checkbox input[type=checkbox] {
  display: none;
}
.filter-checkbox.active {
  padding: 12px 12px 12px 36px;
  position: relative;
  color: #28394F;
  background-color: #E2F5FC;
  border: 1px solid #61A6C7;
  transition: all 0.3s ease;
}
.filter-checkbox.active::after {
  position: absolute;
  left: 12px;
  top: 14px;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  font-size: 16px;
  color: #61A6C7;
}
.filter-checkbox.active:hover, .filter-checkbox.active:active, .filter-checkbox.active:focus {
  text-decoration: none;
  border: 1px solid #61A6C7;
  background-color: #E2F5FC;
}

.filter-top {
  margin-top: 21px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .filter-top {
    align-items: flex-start;
  }
  .filter-top__right {
    margin: 30px 0 0 0;
  }
  .filter-top__right p {
    white-space: wrap;
  }
}
.c-footer p, .c-footer a, .c-footer h4 {
  color: #ffffff;
}
.c-footer__logo {
  margin-bottom: 60px;
}
.c-footer__divider {
  width: 115px;
  height: 1px;
  background-color: #909AB5;
  margin-bottom: 60px;
}
.c-footer__address {
  margin-bottom: 60px;
  font-size: 16px;
  color: #ffffff;
}
.c-footer__nav {
  margin-bottom: 60px;
}
.c-footer__nav a:hover, .c-footer__nav a:active, .c-footer__nav a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-footer__nav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 12px;
}
.c-footer__nav > ul > li {
  margin-bottom: 8px;
}
.c-footer__nav > ul > li > a {
  font-weight: 700;
  color: #ffffff;
  font-size: 14px;
}
.c-footer__nav > ul > li > a:hover, .c-footer__nav > ul > li > a:active, .c-footer__nav > ul > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-footer__nav > ul > li:last-child {
  margin-bottom: 0;
}
.c-footer__nav:last-child {
  margin-bottom: 0px;
}
.c-footer__section {
  margin-bottom: 60px;
}
.c-footer__section:last-child {
  margin-bottom: 0px;
}
.c-footer .sub-footer {
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #909AB5;
}
.c-footer .sub-footer p {
  color: #909AB5;
}
.c-footer .sub-footer a {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #909AB5;
}

@media (max-width: 640px) {
  .c-footer .sub-footer {
    margin-top: 30px;
    flex-flow: column;
    align-items: center;
  }
  .c-footer .sub-footer a {
    text-align: center;
    padding: 4px 0;
  }
}
fieldset {
  border: none;
  padding: 0;
}
fieldset legend {
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  color: #23376C;
  margin-bottom: 24px;
}
fieldset legend span {
  font-size: 18px;
  color: #909AB5;
  font-weight: 400;
}

.input-errors p {
  color: #D60727;
  margin-bottom: 0px;
  margin-top: 0px;
}

.custom-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #61A6C7;
}
.custom-checkbox input[type=checkbox] {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #D3D6E0;
  box-shadow: none;
  flex: 0 0 20px;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
}
.custom-checkbox input[type=checkbox]:checked {
  background-color: #E2F5FC;
  border: 1px solid #61A6C7;
  background-image: url("/dist/assets/images/icons/check.svg");
  background-size: 12px 10px;
}
.custom-checkbox input[type=checkbox][disabled] {
  background-color: #b7b2b2;
  border: 1px solid #b7b2b2;
}
.custom-checkbox div {
  flex: 1 0 auto;
}
.custom-checkbox a {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #61A6C7;
  text-decoration: underline;
  margin: 0 0 0 4px;
  display: contents;
}
.custom-checkbox a:hover, .custom-checkbox a:active, .custom-checkbox a:focus {
  color: #4395bc;
}
.custom-checkbox.uk-form-danger {
  color: #D60727;
}
.custom-checkbox.uk-form-danger input[type=checkbox] {
  border: 2px solid #D60727;
}
.custom-checkbox.disabled p {
  color: #b7b2b2;
}
.custom-checkbox.disabled:hover {
  cursor: no-drop;
}
.custom-checkbox.disabled input[type=checkbox]:hover {
  cursor: no-drop;
}
.custom-checkbox--big input[type=checkbox] {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 8px;
}
.custom-checkbox--big input[type=checkbox]:checked {
  background-image: url("/dist/assets/images/icons/check.svg");
  background-size: 16px 14px;
}

.custom-radio {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}
.custom-radio input[type=radio] {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  margin-right: 10px;
  margin-top: 2px;
  background-color: transparent;
  border: 1px solid #D3D6E0;
}
.custom-radio input[type=radio]:checked {
  border: 1px solid #61A6C7;
  background-color: #61A6C7;
  background-size: 150%;
}

.uk-input,
.uk-textarea {
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #D3D6E0;
  border-radius: 0;
  padding: 0px 20px;
  font-family: myriad-pro, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.uk-input:focus,
.uk-textarea:focus {
  border: 1px solid #61A6C7;
  background-color: #ffffff;
  outline: none;
}
.uk-input.uk-form-danger,
.uk-textarea.uk-form-danger {
  border: 2px solid #D60727;
}
.uk-input.uk-form-danger::placeholder,
.uk-textarea.uk-form-danger::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #D60727;
  opacity: 1;
  /* Firefox */
}
.uk-input.uk-form-danger:-ms-input-placeholder,
.uk-textarea.uk-form-danger:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #D60727;
}
.uk-input.uk-form-danger::-ms-input-placeholder,
.uk-textarea.uk-form-danger::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #D60727;
}

.uk-textarea {
  padding: 20px;
  height: auto;
}

.uk-form-label {
  font-family: myriad-pro, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 10px;
  display: block;
}
.uk-form-label span {
  font-size: 14px;
  color: #909AB5;
  font-weight: 400;
}

form .form-url {
  display: none;
}

form .form-woonplaats {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

select.custom-select {
  padding: 0px 20px;
  height: 50px;
  border-radius: 0;
  border: 1px solid #D3D6E0;
  font-family: myriad-pro, sans-serif;
  background-color: transparent;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/dist/assets/images/icons/angle-down.svg) no-repeat 100% #fff;
  background-position: right 20px center;
}
select.custom-select:active, select.custom-select:focus {
  text-decoration: none;
  outline: none;
  border: 1px solid #61A6C7;
}

.home-banners {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px 30px;
  grid-template-areas: "left top-right" "left bottom-right";
  height: 460px;
}
.home-banners--one {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0;
}
.home-banners--two {
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 30px;
}
.home-banners--left {
  grid-area: left;
}
.home-banners--top-right {
  grid-area: top-right;
}
.home-banners--bottom-right {
  grid-area: bottom-right;
}
.home-banners--top-right .home-banner__content, .home-banners--bottom-right .home-banner__content {
  padding: 40px 33% 40px 40px;
}

.home-banner {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: relative;
  background-color: #61A6C7;
}
.home-banner__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 1;
}
.home-banner__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 60px 25% 60px 60px;
  z-index: 2;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.home-banner--image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #23376C;
}
.home-banner--image .home-banner__content * {
  color: #ffffff !important;
}

@media (max-width: 960px) {
  .home-banner__content {
    padding: 32px;
  }

  .home-banners {
    gap: 15px 15px;
    height: 400px;
    grid-template-columns: 1.5fr 1fr;
  }
  .home-banners--one {
    gap: 0;
  }
  .home-banners--two {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 15px;
  }
  .home-banners--top-right .home-banner__content, .home-banners--bottom-right .home-banner__content {
    padding: 32px;
  }
}
@media (max-width: 640px) {
  .home-banner {
    height: 210px;
  }
  .home-banner__content {
    padding: 20px !important;
  }

  .home-banners {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "left" "top-right" "bottom-right";
    height: 110vh;
  }
}
@media all and (-ms-high-contrast: none) {
  .home-banners {
    display: -ms-grid;
    -ms-grid-columns: 2fr 1fr;
    -ms-grid-rows: 1fr 1fr;
  }
  .home-banners--left {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .home-banners--top-right {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .home-banners--bottom-right {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
}
.c-list-nav {
  margin-top: 24px;
}
.c-list-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-list-nav ul > li {
  min-width: 200px;
  margin-bottom: 12px;
  display: flex;
}
.c-list-nav--2 ul {
  column-count: 2;
}

ol, ul {
  padding-left: 30px;
}
ol li, ul li {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #28394F;
}
ol li::marker, ul li::marker {
  color: #61A6C7;
  font-size: 20px;
}

.c-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 30px;
}
.c-list li {
  margin-bottom: 20px;
  padding-left: 30px;
  position: relative;
}
.c-list li::before {
  content: "\f111";
  position: absolute;
  top: 2px;
  left: 0;
  color: #61A6C7;
  font-family: "Font Awesome 6 Pro";
  font-size: 8px;
  font-weight: bold;
  color: #61A6C7;
}
.c-list li:last-child {
  margin-bottom: 0px;
}
.c-list--check li::before {
  content: "\f00c";
  font-size: 16px;
}
.c-list--arrow li::before {
  content: "\f178";
  font-size: 16px;
}
.c-list--circle-check li::before {
  content: "\f058";
  font-size: 16px;
}

.loader {
  display: inline-block;
}

.c-main-navbar {
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  gap: 20px;
  padding: 15px 0 9px 0;
  align-content: center;
}
.c-main-navbar__wrapper {
  position: relative;
  z-index: 9999;
  background-color: #ffffff;
}
.c-main-navbar__left {
  display: flex;
  align-items: center;
}
.c-main-navbar__left .uk-logo {
  display: block;
}
.c-main-navbar__left img {
  max-width: 100%;
}
.c-main-navbar__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.c-main-navbar__top > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.c-main-navbar__top > ul > li > a {
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #28394F;
}
.c-main-navbar__top > ul > li > a:hover, .c-main-navbar__top > ul > li > a:active, .c-main-navbar__top > ul > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-main-navbar__top > ul > li > a.has-icon {
  display: flex;
  align-items: center;
}
.c-main-navbar__top > ul > li > a.has-icon span {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #61A6C7;
  margin-right: 6px;
  transition: all 0.3s ease;
}
.c-main-navbar__top > ul > li > a.has-icon span i,
.c-main-navbar__top > ul > li > a.has-icon span .fa-solid,
.c-main-navbar__top > ul > li > a.has-icon span .fa-regular,
.c-main-navbar__top > ul > li > a.has-icon span .fa-light,
.c-main-navbar__top > ul > li > a.has-icon span .fa-thin,
.c-main-navbar__top > ul > li > a.has-icon span .fa-brands,
.c-main-navbar__top > ul > li > a.has-icon span svg {
  font-size: 14px;
  color: #ffffff;
}
.c-main-navbar__top > ul > li > a.has-icon:hover, .c-main-navbar__top > ul > li > a.has-icon:active, .c-main-navbar__top > ul > li > a.has-icon:focus {
  text-decoration: none;
}
.c-main-navbar__top > ul > li > a.has-icon:hover span, .c-main-navbar__top > ul > li > a.has-icon:active span, .c-main-navbar__top > ul > li > a.has-icon:focus span {
  background-color: #408fb5;
}
.c-main-navbar__top > ul > li:first-child a {
  padding-left: 0;
}
.c-main-navbar__top > ul > li:last-child a {
  padding-right: 0;
}
.c-main-navbar__dropdown {
  top: 70px !important;
  min-width: 120px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
}
.c-main-navbar__dropdown > ul > li > a {
  font-size: 16px;
  font-weight: 400;
  color: #28394F;
}
.c-main-navbar__dropdown > ul > li > a:hover, .c-main-navbar__dropdown > ul > li > a:active, .c-main-navbar__dropdown > ul > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-main-navbar__dropdown > ul > li > a.is-active {
  color: #4193BA;
}
.c-main-navbar__bttm > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.c-main-navbar__bttm > ul > li > a {
  padding: 15px 20px 30px 20px;
  font-size: 18px;
  font-weight: 700;
  color: #23376C;
  position: relative;
}
.c-main-navbar__bttm > ul > li > a:hover, .c-main-navbar__bttm > ul > li > a:active, .c-main-navbar__bttm > ul > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-main-navbar__bttm > ul > li > a:hover::after, .c-main-navbar__bttm > ul > li > a:active::after, .c-main-navbar__bttm > ul > li > a:focus::after {
  content: "";
  height: 3px;
  background-color: #61A6C7;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
}
.c-main-navbar__bttm > ul > li > a.is-active {
  color: #4193BA;
}
.c-main-navbar__bttm > ul > li > a.is-active::after {
  content: "";
  height: 3px;
  background-color: #61A6C7;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
}
.c-main-navbar__bttm > ul > li:first-child a {
  padding-left: 0;
}
.c-main-navbar__bttm > ul > li:first-child a:hover::after, .c-main-navbar__bttm > ul > li:first-child a:active::after, .c-main-navbar__bttm > ul > li:first-child a:focus::after {
  left: 0px;
}
.c-main-navbar__bttm > ul > li:first-child a.is-active::after {
  left: 0px;
}
.c-main-navbar__bttm > ul > li:last-child a {
  padding-right: 0;
}
.c-main-navbar__bttm > ul > li:last-child a:hover::after, .c-main-navbar__bttm > ul > li:last-child a:active::after, .c-main-navbar__bttm > ul > li:last-child a:focus::after {
  right: 0px;
}
.c-main-navbar__bttm > ul > li:last-child a.is-active::after {
  right: 0px;
}
.c-main-navbar__megadropdown {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  border-top: 1px solid #D3D6E0;
  padding: 60px 0;
  display: none;
}
.c-main-navbar__megadropdown.is-shown {
  display: block;
}

.c-mobile-menu .uk-offcanvas-bar {
  width: 375px;
  right: -375px;
  padding: 0;
  position: absolute;
  overflow-y: scroll;
  transition: right 0.6s ease;
}
.c-mobile-menu.uk-open .uk-offcanvas-bar {
  right: 0;
}
.c-mobile-menu__header {
  position: relative;
}
.c-mobile-menu .uk-offcanvas-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.c-mobile-menu__content {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  padding: 30px 40px 30px 0;
  margin: 0;
  list-style-type: none;
}
.c-mobile-menu__primary-nav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.c-mobile-menu__primary-nav > ul > li > a {
  padding: 15px 20px 15px 40px;
  font-size: 18px;
  font-weight: 700;
  color: #23376C;
  position: relative;
}
.c-mobile-menu__primary-nav > ul > li > a:hover, .c-mobile-menu__primary-nav > ul > li > a:active, .c-mobile-menu__primary-nav > ul > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-mobile-menu__primary-nav > ul > li > a:hover::after, .c-mobile-menu__primary-nav > ul > li > a:active::after, .c-mobile-menu__primary-nav > ul > li > a:focus::after {
  content: "";
  width: 3px;
  background-color: #61A6C7;
  position: absolute;
  left: 0px;
  top: 10px;
  bottom: 10px;
}
.c-mobile-menu__primary-nav > ul > li > a.is-active {
  color: #4193BA;
}
.c-mobile-menu__primary-nav > ul > li > a.is-active::after {
  content: "";
  width: 3px;
  background-color: #61A6C7;
  position: absolute;
  left: 0px;
  top: 10px;
  bottom: 10px;
}
.c-mobile-menu__primary-nav .is-niv2 {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 0px;
  flex-direction: column;
}
.c-mobile-menu__primary-nav .is-niv2 > li > a {
  padding: 15px 20px 15px 60px;
  font-size: 18px;
  font-weight: 700;
  color: #23376C;
}
.c-mobile-menu__primary-nav .is-niv2 > li > a:hover, .c-mobile-menu__primary-nav .is-niv2 > li > a:active, .c-mobile-menu__primary-nav .is-niv2 > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-mobile-menu__primary-nav .is-niv2.is-open {
  display: flex;
}
.c-mobile-menu__primary-nav .is-niv3 {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 0px;
  flex-direction: column;
}
.c-mobile-menu__primary-nav .is-niv3 > li > a {
  padding: 15px 20px 15px 80px;
  cursor: pointer;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #28394F;
  padding-right: 28px;
  position: relative;
}
.c-mobile-menu__primary-nav .is-niv3 > li > a::before {
  font-size: 16px;
  position: absolute;
  content: "\f178";
  font-family: "Font Awesome 6 Pro";
  font-weight: bold;
  color: #61A6C7;
  right: 5px;
  top: 15px;
  transition: right 0.3s ease;
}
.c-mobile-menu__primary-nav .is-niv3 > li > a:hover, .c-mobile-menu__primary-nav .is-niv3 > li > a:active, .c-mobile-menu__primary-nav .is-niv3 > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-mobile-menu__primary-nav .is-niv3.is-open {
  display: flex;
}
.c-mobile-menu__secondary-nav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.c-mobile-menu__secondary-nav > ul > li {
  margin: 0;
}
.c-mobile-menu__secondary-nav > ul > li > a {
  padding: 15px 15px 15px 40px;
  font-size: 16px;
  font-weight: 400;
  color: #28394F;
}
.c-mobile-menu__secondary-nav > ul > li > a:hover, .c-mobile-menu__secondary-nav > ul > li > a:active, .c-mobile-menu__secondary-nav > ul > li > a:focus {
  text-decoration: none;
  color: #4193BA;
}
.c-mobile-menu__secondary-nav > ul > li > a.has-icon {
  display: flex;
  align-items: center;
}
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #61A6C7;
  margin-right: 6px;
  transition: all 0.3s ease;
}
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span i,
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span .fa-solid,
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span .fa-regular,
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span .fa-light,
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span .fa-thin,
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span .fa-brands,
.c-mobile-menu__secondary-nav > ul > li > a.has-icon span svg {
  font-size: 14px;
  color: #ffffff;
}
.c-mobile-menu__secondary-nav > ul > li > a.has-icon:hover, .c-mobile-menu__secondary-nav > ul > li > a.has-icon:active, .c-mobile-menu__secondary-nav > ul > li > a.has-icon:focus {
  text-decoration: none;
}
.c-mobile-menu__secondary-nav > ul > li > a.has-icon:hover span, .c-mobile-menu__secondary-nav > ul > li > a.has-icon:active span, .c-mobile-menu__secondary-nav > ul > li > a.has-icon:focus span {
  background-color: #408fb5;
}
.c-mobile-menu__languages ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  padding-left: 40px;
  margin-top: 40px;
}
.c-mobile-menu__languages ul li a {
  padding: 4px 16px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  color: #28394F;
  border-left: 2px solid #D3D6E0;
}
.c-mobile-menu__languages ul li a:hover, .c-mobile-menu__languages ul li a:active, .c-mobile-menu__languages ul li a:focus {
  text-decoration: none;
  color: #61A6C7;
}
.c-mobile-menu__languages ul li a.is-active {
  color: #61A6C7;
}
.c-mobile-menu__languages ul li:first-child a {
  padding-left: 0;
  border-left: none;
}

@media (max-width: 640px) {
  .c-mobile-menu .uk-offcanvas-bar {
    width: 100vw;
    right: -100vw;
  }
}
.c-mobile-nav {
  background-color: #ffffff;
  padding: 20px 0;
}
.c-mobile-nav .uk-navbar-toggle {
  color: #222222;
  margin-right: 20px;
  padding: 0;
  min-height: 0;
}
.c-mobile-nav .uk-logo {
  padding: 0;
}
.c-mobile-nav .uk-navbar-item {
  min-height: 0;
}
.c-mobile-nav .uk-navbar-nav li a {
  padding: 0 10px;
  min-height: 0;
  color: #222222;
}

@media (max-width: 640px) {
  #mobile-nav .uk-offcanvas-bar {
    width: 90vw;
    left: -90vw;
  }
  #mobile-nav.uk-open .uk-offcanvas-bar {
    left: 0;
  }
}
@media (max-width: 960px) {
  .mobile-slider-wrapper {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .mobile-slider-wrapper .mobile-slider {
    overflow: visible;
  }
}
.c-modal {
  z-index: 9999999;
}
.c-modal .uk-modal-dialog {
  width: 1300px;
}
.c-modal .uk-modal-close-default {
  top: 30px;
  right: 30px;
}
.c-modal .uk-modal-body {
  padding: 0;
  display: flex;
}
.c-modal__img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-width: 400px;
}
.c-modal__content {
  padding: 100px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 960px) {
  .c-modal .uk-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .c-modal__img {
    width: 100%;
    max-width: unset;
    height: 300px;
  }
  .c-modal__content {
    padding: 100px 60px 60px 60px;
  }
}
.newsletter form {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin: 0px;
}
.newsletter form button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}
.newsletter .input-errors {
  color: #D60727;
}
.newsletter .input-errors p {
  color: #D60727;
}
.newsletter .uk-input {
  border: none;
}
.newsletter .uk-button-primary {
  background-color: #E2F5FC;
  border-radius: 0;
  border: 1px solid #E2F5FC;
  color: #23376C;
}
.newsletter .uk-button-primary:hover {
  background-color: #bde8f8;
  border: 1px solid #bde8f8;
}

.slider-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #61A6C7;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.slider-button:focus, .slider-button:active {
  background-color: #ffffff;
  color: #61A6C7;
  text-decoration: none;
}

.c-slider__wrapper {
  margin-top: 60px;
  position: relative;
}
.c-slider__bttn {
  border: none;
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  color: #ffffff;
  border-radius: 999px;
  background-color: #23376C;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  transition: all 0.3s ease;
}
.c-slider__bttn:hover, .c-slider__bttn:active, .c-slider__bttn:focus {
  color: #ffffff;
  background-color: #172345;
  text-decoration: none;
  transform: scale(1.05);
}
.c-slider--left {
  position: absolute;
  left: -20px;
  top: calc(50% - 20px);
}
.c-slider--right {
  position: absolute;
  right: -20px;
  top: calc(50% - 20px);
}

.c-text-img-big {
  min-height: 500px;
}
.c-text-img-big__inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}
.c-text-img-big__image-left {
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50vw;
  height: 500px;
  border-radius: 0 20px 20px 0;
}
.c-text-img-big__image-right {
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50vw;
  height: 500px;
  border-radius: 20px 0 0 20px;
}

@media (max-width: 960px) {
  .c-text-img-big {
    min-height: unset;
  }
  .c-text-img-big__inner-wrapper {
    min-height: unset;
    margin-bottom: 360px;
  }
  .c-text-img-big__image-left {
    top: unset;
    bottom: 20px;
    width: calc(100vw - 60px);
    height: 300px;
  }
  .c-text-img-big__image-right {
    top: unset;
    bottom: 20px;
    width: calc(100vw - 60px);
    height: 300px;
  }
}
@media (max-width: 640px) {
  .c-text-img-big__inner-wrapper {
    margin-bottom: 240px;
  }
  .c-text-img-big__image-left {
    width: calc(100vw - 12px);
    height: 200px;
  }
  .c-text-img-big__image-right {
    width: calc(100vw - 12px);
    height: 200px;
  }
}
.c-timeline__year {
  color: #61A6C7;
  font-size: 21px;
  margin: 0 0 16px 0;
}
.c-timeline__title {
  font-size: 18px;
  color: #23376C;
  font-weight: 21px;
  margin: 0 0 8px 0;
}
.c-timeline__section {
  border-radius: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 24px 0 40px 60px;
  text-align: start;
  position: relative;
}
.c-timeline__section::before {
  content: "";
  width: 2px;
  background-color: #E2F5FC;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 32px;
}
.c-timeline__section::after {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #61A6C7;
  position: absolute;
  left: 25px;
  top: 27px;
}
.c-timeline__section.is-active::before {
  background-color: #23376C;
}
.c-timeline__section.is-active::after {
  background-color: #23376C;
}
.c-timeline__section.is-active .c-timeline__title {
  font-size: 24px;
}
.c-timeline__section.is-active .t-cms p {
  font-size: 16px;
  line-height: 21px;
}

.top-banner {
  background-color: #23376C;
}
.top-banner--title {
  color: #ffffff;
}
.top-banner--text {
  color: #ffffff;
}
.top-banner--text p {
  color: #ffffff;
}
.top-banner--text p:last-of-type {
  margin-bottom: 0;
}

.c-usp {
  display: flex;
  align-items: flex-start;
}
.c-usp__icon {
  margin-right: 20px;
  font-size: 24px;
  color: #61A6C7;
}
.c-usp__image {
  margin-right: 20px;
}
.c-usp__title {
  font-weight: 700;
  font-size: 18px;
  color: #23376C;
  margin-bottom: 4px;
}
.c-usp__text {
  margin: 0;
}