.TEMPLATE {
   
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
    .mobile-slider-wrapper {
        max-width: 100vw;
        overflow-x: hidden;
    
        .mobile-slider {
          overflow: visible;
        }
    }
}

// Mobile devices
@media (max-width: 640px) {
}