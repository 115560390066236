.c-list-nav {
    margin-top: 24px;

    ul {
        @include reset-list;

        > li {
            min-width: 200px;
            margin-bottom: 12px;
            display: flex;
        }
    }

    &--2 {
        ul {
            column-count: 2;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}