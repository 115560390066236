.active-filter {
    display      : flex;
    align-items  : baseline;
    padding      : 8px 10px;
    border       : 1px solid $grey;
    border-radius: 3px;
  
    span {
      font-weight: 300;
      font-size  : 14px;
      line-height: 21px;
      color      : $black;
    }
  
    i,
    svg {
      font-size  : 12px;
      color      : $grey-dark;
      margin-left: 5px;
    }
  
    &:hover{
      cursor: pointer;
      i,
      svg{
        color: $danger-color;
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}