.checkout-steps {
    display              : grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows   : 1fr;
    gap                  : 0px 10px;
    grid-template-areas  : '. . . . .';
    border-radius        : 3px;
    overflow             : hidden;
    padding-right: 16px;
  
    .checkout-step {
      flex            : 1 0 auto;
      background-color: $primary;
      padding         : 12px 0px 12px 20px;
      font-weight     : bold;
      font-size       : 16px;
      line-height     : 24px;
      color           : $white;
      display         : flex;
      justify-content : center;
      align-items     : center;
      position        : relative;
  
      &:before {
        left             : 100%;
        top              : 50%;
        border           : solid transparent;
        content          : ' ';
        height           : 0;
        width            : 0;
        position         : absolute;
        pointer-events   : none;
        border-color     : rgba(136, 183, 213, 0);
        border-left-color: $primary;
        border-width     : 24px;
        margin-top       : -24px;
      }
  
      &:after {
        left             : 0%;
        top              : 50%;
        border           : solid transparent;
        content          : ' ';
        height           : 0;
        width            : 0;
        position         : absolute;
        pointer-events   : none;
        border-color     : rgba(136, 183, 213, 0);
        border-left-color: $white;
        border-width     : 24px;
        margin-top       : -24px;
      }
  
      &.uk-active {
        color           : $white;
        background-color: $primary;
  
        &:before {
          border-left-color: $primary;
        }
      }
  
      &.uk-past {
        color           : $white;
        background-color: $secondary;
  
        &:before {
          border-left-color: $secondary;
        }
      }
  
      &:nth-child(1) {
        z-index: 5;
  
        &:after {
          display: none;
        }
      }
  
      &:nth-child(2) {
        z-index: 4;
      }
  
      &:nth-child(3) {
        z-index: 3;
      }
  
      &:nth-child(4) {
        z-index: 2;
      }
  
      &:nth-child(5) {
        z-index: 1;
  
        &:before {
          display: none;
        }
      }
    }
  }
  


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
  
}

// Mobile devices
@media (max-width: 640px) {
  .checkout-steps {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: '.' '.' '.' '.';
    grid-gap: 8px;
    padding-right: 0px;

    .checkout-step {
      justify-content: flex-start;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }
  }
}