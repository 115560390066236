.c-product-small {
	cursor: pointer;
	border-radius: 20px;
	box-shadow: $shadow;
	background-color: $white;
	padding: 16px;
	border: none;
    display: flex;
	align-items: center;
	width: 100%;
    
    &__middel {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: start;
    }

    &__image {
		margin-right: 16px;
		height: 50px;
		width: 50px;
    }

    &__title {
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		color: $secondary;
		margin-top: 0;
    }

	&__nr {
		margin-top: 4px;
		font-size: 14px;
		color: $muted;
	}

	&__add {
		margin-left: 40px;
		height: 40px;
		min-height: 40px;
		width: 40px;
		min-width: 40px;
		border-radius: 50px;
		background-color: $secondary;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .3s ease;
		font-size: 16px;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: darken($color: $secondary, $amount: 10%);
			transform: scale(1.01);
		}
	}

	&__remove {
		margin-left: 40px;
		height: 40px;
		min-height: 40px;
		width: 40px;
		min-width: 40px;
		border-radius: 50px;
		background-color: $muted;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .3s ease;
		font-size: 16px;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: $danger-color;
			transform: scale(1.01);
		}
	}

    &:hover,
    &:active,
    &:focus {
		text-decoration: none;
	
		.c-product__title {
			color: $primary;
			word-break: keep-all;
			white-space: normal;
		}
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
	.c-product-small {
		padding: 12px;

		&__image {
			margin-right: 8px;
		}

		&__title {
			font-size: 16px;
		}

		&__add {
			margin-left: 16px;
		}

		&__remove {
			margin-left: 16px;
		}
	}
}