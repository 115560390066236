.info{
    &--circle {
      color: $primary!important;
      transform:rotate(360deg)!important;
      font-size: 16px!important;
    }
  
    &--modal {
        h2{
            color: $primary;
        }
    }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}