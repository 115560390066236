/*========================================================
=                       Utilities                        =
=========================================================*/

/* positioning & alignment
-------------------------------------------------- */

.push-down,
.flex-grow {
    flex-grow: 1;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.pull-right--mini {
    margin-right: -1px;
}

.pull-bottom--mini {
    margin-bottom: -1px;
}

.h-p-rel {
    position: relative;
}

.h-p-abs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.h-p-center {
    margin: auto;
    display: block;
}

.h-wr-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.object-fit {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

/* blend modes
-------------------------------------------------- */

.blend {
    &--multiply {
        mix-blend-mode: multiply;
    }
}

/* Spacing
-------------------------------------------------- */

.margin-top-verthus {
    margin-top: 30px;
}

@for $i from 0 through 40 {
    $j: $i * 5;

    .h-mt--#{$j} {
        margin-top: 1px * $j;
    }

    .h-mr--#{$j} {
        margin-right: 1px * $j;
    }

    .h-mb--#{$j} {
        margin-bottom: 1px * $j;
    }

    .h-ml--#{$j} {
        margin-left: 1px * $j;
    }
}

@for $i from 0 through 40 {
    $j: $i * 5;

    .h-pt--#{$j} {
        padding-top: 1px * $j;
    }

    .h-pr--#{$j} {
        padding-right: 1px * $j;
    }

    .h-pb--#{$j} {
        padding-bottom: 1px * $j;
    }

    .h-pl--#{$j} {
        padding-left: 1px * $j;
    }
}

/* Sizing
-------------------------------------------------- */

.h-w-15 {
    width: 15%;
}

.h-w-25 {
    width: 25%;
}

.h-w-50 {
    width: 50%;
}

.h-w-75 {
    width: 75%;
}

.h-w-100 {
    width: 100%;
}

.h-h-100 {
    height: 100%;
}

/* Border
-------------------------------------------------- */

.h-br-circle {
    border-radius: 99999px;
}

.h-br-top {
    border-radius: 25px 25px 0 0;
}

.h-br-25 {
    border-radius: 25px;
}

hr {
    border-top: 1px solid $border;
}

/* Backgrounds
-------------------------------------------------- */

.bg {
    &--primary {
        background: $primary;
    }

    &--secondary {
        background: $secondary;
    }
}

/* Hover
-------------------------------------------------- */
.no-hover {
    &:hover {
        text-decoration: none;
    }
}
