.c-modal {
    z-index: 9999999;
    // position: relative;

    // UIKIT classes
    .uk-modal-dialog {
        width: 1300px;
    }

    .uk-modal-close-default {
        top: 30px;
        right: 30px;
    }

    .uk-modal-body {
        padding: 0;
        display: flex;
        // position: relative;
    }

    // custom
    &__img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        max-width: 400px;
    }

    &__content {
        padding: 100px;
        display: flex;
        flex-direction: column;

    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
    .c-modal {
        .uk-modal-body {
            padding: 0;
            display: flex;
            // position: relative;
            flex-direction: column-reverse;
        }
    
        // custom
        &__img {
            width: 100%;
            max-width: unset;
            height: 300px;
        }
    
        &__content {
            padding: 100px 60px 60px 60px;
        }
    }
}

// Mobile devices
@media (max-width: 640px) {
}