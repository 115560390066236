ol, ul {
    padding-left: 30px;
  
    li {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        color: $text;
    
        &::marker{
            color: $primary;
            font-size: 20px;
        }
    }
}

.c-list {
    @include reset-list;
    margin-top: 30px;

    li {
        margin-bottom: 20px;
        padding-left: 30px;
        position: relative;
        
        &::before {
            content: '\f111';
            position: absolute;
            top: 2px;
            left: 0;
            color: $primary;
            font-family: 'Font Awesome 6 Pro';
            font-size: 8px;
            font-weight: bold;
            color: $primary;
        }
        
        &:last-child {
            margin-bottom: 0px;
        }
    }
    
    &--check {
        li {
            &::before {
                content: '\f00c';
                font-size: 16px;
            }
        }
    }
    
    &--arrow {
        li {
            &::before {
                content: '\f178';
                font-size: 16px;
            }
        }
    }
    
    &--circle-check {
        li {
            &::before {
                content: '\f058';
                font-size: 16px;
            }
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}