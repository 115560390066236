.delivery {
    display              : grid;
    grid-template-columns: repeat(4, 1fr);
    gap                  : 10px 10px;

    &__existing {
        border          : 1px solid $border;
        background-color: transparent;
        border-radius   : $border-radius;
        padding         : 30px;
        text-align      : left;
        cursor          : pointer;

        font-family: $ff-stack;
        font-weight: 300;
        font-size  : 16px;
        line-height: 24px;
        color      : $black;

        &:hover {
            background-color: $secondary;
            border          : 1px solid $primary;
            color           : $primary !important;
        }

        &.active {
            box-shadow      : inset 0 0 0 2px $primary;
            border          : 1px solid $primary;
            background-color: $secondary;
        }
    }

    &__new {
        position        : relative;
        background-color: $secondary;
        border          : 0;
        border-radius   : $border-radius;
        display         : flex;
        justify-content : center;
        align-items     : center;

        font-family: $ff-stack;
        font-weight: 300;
        font-size  : 16px;
        line-height: 24px;
        color      : $black;
        cursor     : pointer;

        i,
        svg {
            font-size: 14px;
        }

        &.active{
            background-color: $primary;
            color           : $white;
            font-weight     : bold;
            text-decoration : none;

            &::after {
                display: block;
            }
        }

        &::after {
            top             : 100%;
            left            : 50%;
            border          : solid transparent;
            content         : ' ';
            height          : 0;
            width           : 0;
            position        : absolute;
            pointer-events  : none;
            border-color    : transparent;
            border-top-color: $primary;
            border-width    : 10px;
            margin-left     : -10px;
            display         : none;
        }

        &:hover {
            background-color: $primary;
            color           : $white;
            font-weight     : bold;
            text-decoration : none;

            &::after {
                display: block;
            }
        }
    }
}

#new-delivery, #new-billing {
    border     : 1px solid $border;
    margin-top : 30px;
    margin-left: 0;
    padding    : 23px 30px 30px 15px;
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
    .delivery {
        grid-template-columns: repeat(2, 1fr);
    }
}

// Mobile devices
@media (max-width: 640px) {
    .delivery {
        grid-template-columns: repeat(1, 1fr);
    
        &__new {
            height: 80px;
        }
    }
}