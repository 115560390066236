/* iframe videos
-------------------------------------------------- */

.vimeo-iframe, .youtube-iframe, .other-iframe{
  padding-bottom: 56.67%;
  position: relative;
  width: 100%;
}

.video-iframe{
  height: 100%;
  left: 0;
  position: absolute;
  width: 100% !important;
}

// ==========[ WYSIWYG / PAGE BUILDER ]==========

.full-left-image,
.full-right-image {
  position           : absolute;
  top                : 0;
  bottom             : 0;
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : cover;
}

.full-left-image {
  right: 50%;
  left : 0;
}

.full-right-image {
  right: 0;
  left : 50%;
}

// ==========[ IMAGE BLOCKS ]==========

.image-block {
  display            : block;
  position           : relative;
  width              : 100%;
  padding-top        : 75%;
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : cover;

  &.square {
    padding-top: 100%;
  }

  &.long {
    padding-top: 133%;
  }

  &--half {
    padding-top: 56%;
  }

  &.contain {
    background-size: contain;
  }
}


/* Modals
-------------------------------------------------- */

.modal {
  position        : fixed;
  top             : 0;
  right           : 0;
  bottom          : 0;
  left            : 0;
  background-color: transparentize($color: #e7f8f8, $amount: 0.05);
  z-index         : 9999;
  display         : flex;
  justify-content : center;
  align-items     : center;
  padding         : 100px 0;
  overflow-y      : scroll;

  &--big {
    align-items: flex-start;
  }

  &__box {
    width           : 100%;
    max-width       : 1050px;
    background-color: $white;
    border-radius   : 3px;
    box-shadow      : 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
    padding         : 45px 60px;

    &--image {
      position: relative;
    }
  }

  &__image {
    position: absolute;
    top     : 0;
    left    : 0;
    bottom  : 0;
    right   : 66.66%;

    background-size    : cover;
    background-repeat  : no-repeat;
    background-position: center center;
  }

  &__content {
    padding-left: calc(33.33% + 50px);
  }
}

/* Category blocks
-------------------------------------------------- */
.block-link-category-wrapper {
  text-align: center;
  span {
    font-weight: bold;
    font-size  : 18px;
    line-height: 27px;
    color      : $black;
  }

  &:hover {
    text-decoration: none;

    span {
      color: $primary;
      text-decoration: none!important;
    }

    .block-link-category {
      background-color: $primary;
      box-shadow      : 0 2px 8px rgba(0, 0, 0, 0.08);
    }

    .block-image-link-category {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }
  }
}
.block-link-category {
  position        : relative;
  background-color: $secondary;
  display         : block;
  width           : 100%;
  padding-top     : 75%;
  border-radius   : 3px;
  transition      : background-color 0.3s ease, box-shadow 0.3s ease;

  span {
    position : absolute;
    left     : 25px;
    right    : 25px;
    top      : 50%;
    transform: translateY(-50%);

    font-weight: bold;
    font-size  : 18px;
    line-height: 27px;
    text-align : center;
    color      : $black;
    transition : color 0.3s ease;
  }

  &:hover {
    background-color: $primary;
    box-shadow      : 0 2px 8px rgba(0, 0, 0, 0.08);

    span {
      color: $white;
    }
  }
}

.block-image-link-category {
  position           : relative;
  display            : block;
  width              : 100%;
  padding-top        : 75%;
  border-radius      : 3px;
  background-size    : contain;
  background-repeat  : no-repeat;
  background-position: center center;
  transition         : background-color 0.3s ease, box-shadow 0.3s ease;
  overflow           : hidden;

  .overlay {
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    transition      : background-color 0.3s ease;
  }

  span {
    position: absolute;
    left    : 25px;
    right   : 25px;
    bottom  : 25px;

    font-weight: bold;
    font-size  : 18px;
    line-height: 27px;
    text-align : center;
    color      : $white;
    transition : color 0.3s ease;
  }

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    .overlay {
      background-color: transparentize($color: $primary, $amount: 0.3);
    }
  }
}