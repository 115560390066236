.product-row {
    display              : grid;
    grid-template-columns: 1fr 2fr 0.75fr 1.5fr;
    gap                  : 30px 30px;
  
    .image-block {
        background-size: contain;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
    .product-row {
        display: initial;
  
        grid-template-columns: 1fr 3fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: '. .' '. .';
        
        .image-block {
            padding-top: 50%;
            width: 100%;
            margin: 0 auto;
        }
    }
}

// Mobile devices
@media (max-width: 640px) {
    .product-row {
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(min-content, max-content);
        grid-template-areas: '.' '.' '.' '.';
        align-items: start;
    }
}