.add-to-cart {
    .uk-offcanvas-bar {
        width: 500px;
        left: -500px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
        padding: 60px;
    }
  
    &.uk-open {
        .uk-offcanvas-bar {
            left: 0;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
    .add-to-cart {
        .uk-offcanvas-bar {
            padding: 25px;
            width: 100vw;
            left: -100vw;
        }
    }
}