
.home-banners {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows   : 1fr 1fr;
    gap                  : 30px 30px;
    grid-template-areas  : 'left top-right''left bottom-right';
    height: 460px;
  
    &--one{
      grid-template-columns: 1fr;
      grid-template-rows   : 1fr;
      gap                  : 0;
    }
  
    &--two{
      grid-template-columns: 2fr 1fr;
      grid-template-rows   : 1fr;
      gap                  : 0 30px;
    }
  
    &--left {
      grid-area: left;
    }
  
    &--top-right {
      grid-area: top-right;
    }
  
    &--bottom-right {
      grid-area: bottom-right;
    }
  
    &--top-right,
    &--bottom-right {
      .home-banner {
        &__content {
          padding: 40px 33% 40px 40px;
        }
      }
    }
  }
  
  .home-banner {
    width           : 100%;
    height          : 100%;
    border-radius   : $border-radius;
    position        : relative;
    background-color: $primary;
  
    &__overlay {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
      z-index   : 1;
    }
  
    &__content {
      position       : absolute;
      top            : 0;
      right          : 0;
      bottom         : 0;
      left           : 0;
      padding        : 60px 25% 60px 60px;
      z-index        : 2;
      display        : flex;
      flex-flow      : column;
      justify-content: flex-end;
      align-items    : flex-start;
    }
  
    &--image {
      background-size    : cover;
      background-repeat  : no-repeat;
      background-position: center center;
      background-color   : $secondary;
  
      .home-banner__content {
        * {
          color: $white !important;
        }
      }
    }
  }


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
        .home-banner {
            &__content {
                padding: 32px;
            }
        }
    
      .home-banners {
        gap: 15px 15px;
        height: 400px;
        grid-template-columns: 1.5fr 1fr;
    
        &--one{
            gap: 0;
        }
    
        &--two{
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr;
            gap: 0 15px;
        }
    
        &--top-right,
        &--bottom-right {
            .home-banner {
                &__content {
                    padding: 32px;
                }
            }
        }
    }
}

// Mobile devices
@media (max-width: 640px) {
    .home-banner {
        height: 210px;
    
        &__content {
          padding: 20px !important;
        }
      }
    
      .home-banners {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: 'left' 'top-right' 'bottom-right';
        height: 110vh;
      }
}

// MS IE
@media all and (-ms-high-contrast: none) {
    .home-banners {
      display: -ms-grid;
      -ms-grid-columns: 2fr 1fr;
      -ms-grid-rows: 1fr 1fr;
  
      &--left {
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
      }
  
      &--top-right {
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
      }
  
      &--bottom-right {
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
      }
    }
}
  