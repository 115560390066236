.c-main-navbar {
	&__wrapper {
		position: relative;
		z-index: 9999;
		background-color: $white;
	}

    display              : grid;
    grid-template-columns: 1fr 3.5fr;
    gap                  : 20px;
    padding              : 15px 0 9px 0;
    align-content        : center;

    &__left {
		display: flex;
		align-items: center;

      	.uk-logo {
			display: block;
		}
	
		img {
			max-width: 100%;
		}
    }
  
    &__right {
		display        : flex;
		flex-direction : column;
		justify-content: center;
		align-items    : flex-end;
    }

	&__top {
		> ul {
			@include reset-list;
			display: flex;
			align-items: center;

			> li {
				
				> a {
					padding: 15px;
					font-size: 16px;
					font-weight: 400;
					color: $text;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color: $text_highlight;
					}

					&.has-icon {
						display: flex;
						align-items: center;

						span {
							width: 24px;
							height: 24px;
							border-radius: 24px;
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: $primary;
							margin-right: 6px;
							transition: all .3s ease;

							i,
							.fa-solid,
							.fa-regular,
							.fa-light,
							.fa-thin,
							.fa-brands,
							svg {
								font-size: 14px;
								color: $white;
							}
						}

						&:hover,
						&:active,
						&:focus {
							text-decoration: none;

							span {
								background-color: darken($color: $primary, $amount: 10%);
							}
						}
					}
				}

				&:first-child {
					a {
						padding-left: 0;
					}
				}
				
				&:last-child {
					a {
						padding-right: 0;
					}
				}
			}
		}
	}

	&__dropdown {
		top: 70px !important;
		min-width: 120px;
		background-color: $white;
		box-shadow: $shadow;

		> ul {
			> li {
				> a {
					font-size: 16px;
					font-weight: 400;
					color: $text;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color: $text_highlight
					}

					&.is-active {
						color: $text_highlight
					}
				}
			}
		}
	}

	&__bttm {
		> ul {
			@include reset-list;
			display: flex;
			align-items: center;
			
			> li {
				> a {
					padding: 15px 20px 30px 20px;
					font-size: 18px;
					font-weight: 700;
					color: $secondary;
					position: relative;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color: $text_highlight;

						&::after {
							content: '';
							height: 3px;
							background-color: $primary;
							position: absolute;
							left: 20px;
							right: 20px;
							bottom: 0;
						}
					}

					&.is-active {
						color: $text_highlight;

						&::after {
							content: '';
							height: 3px;
							background-color: $primary;
							position: absolute;
							left: 20px;
							right: 20px;
							bottom: 0;
						}
					}
				}

				&:first-child {
					a {
						padding-left: 0;

						&:hover,
						&:active,
						&:focus {

							&::after {
								left: 0px;
							}
						}

						&.is-active {
							&::after {
								left: 0px;
							}
						}
					}
				}
				
				&:last-child {
					a {
						padding-right: 0;

						&:hover,
						&:active,
						&:focus {

							&::after {
								right: 0px;
							}
						}

						&.is-active {
							&::after {
								right: 0px;
							}
						}
					}
				}
			}
		}
	}

	&__megadropdown {
		z-index: 999;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-color: $white;
		box-shadow: $shadow;
		border-top: 1px solid $border;
		padding: 60px 0;
		display: none;

		&.is-shown {
			display: block;
		}
	}
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}