fieldset {
    border: none;
    padding: 0;

    legend {
        font-weight: bold;
        font-size: 24px;
        line-height: 28.8px;
        color: $secondary;
        margin-bottom: 24px;

        span {
            font-size: 18px;
            color: $muted;
            font-weight: 400;
        }
    }
}

.input-errors {
    p {
        color: $danger-color;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

.custom-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: $primary;

    input[type='checkbox'] {
        width: 20px;
        height: 20px;
        background-color: $white;
        border: 1px solid $border;
        box-shadow: none;
        flex: 0 0 20px;
        margin-right: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        border-radius: 6px;


        &:checked {
            background-color: $bg_blue;
            border: 1px solid $primary;
            background-image: url("/dist/assets/images/icons/check.svg");
            background-size: 12px 10px;
        }

        &[disabled] {
            background-color: $grey-disabled;
            border: 1px solid $grey-disabled;
        }
    }

    div {
        flex: 1 0 auto;
    }

    a {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: $primary;
        text-decoration: underline;
        margin: 0 0 0 4px;
        display: contents;

        &:hover,
        &:active,
        &:focus {
            color: darken($primary, 8%);
        }
    }

    &.uk-form-danger {
        color: $danger-color;

        input[type='checkbox'] {
            border: 2px solid $danger-color;
        }
    }

    &.disabled {
        p {
            color: $grey-disabled;
        }

        &:hover {
            cursor: no-drop;
        }

        input[type='checkbox'] {
            &:hover {
                cursor: no-drop;
            }
        }
    }

    &--big {
        input[type='checkbox'] {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            border-radius: 8px;

            &:checked {
                background-image: url("/dist/assets/images/icons/check.svg");
                background-size: 16px 14px;
            }
        }
    }
}

.custom-radio {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

    input[type='radio'] {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        margin-right: 10px;
        margin-top: 2px;

        background-color: transparent;
        border: 1px solid $border;

        &:checked {
            border: 1px solid $primary;
            background-color: $primary;
            background-size: 150%;
        }
    }
}

.uk-input,
.uk-textarea {
    height: 50px;
    background-color: $white;
    border: 1px solid $border;
    border-radius: 0;
    padding: 0px 20px;

    font-family: $ff-stack;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    &:focus {
        border: 1px solid $primary;
        background-color: $white;
        outline: none;
    }

    &.uk-form-danger {
        border: 2px solid $danger-color;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $danger-color;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $danger-color;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $danger-color;
        }
    }
}

.uk-textarea {
    padding: 20px;
    height: auto;
}

.uk-form-label {
    font-family: $ff-stack;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    margin-bottom: 10px;
    display: block;

    span {
        font-size: 14px;
        color: $muted;
        font-weight: 400;
    }
}

form .form-url {
    display: none;
}

form .form-woonplaats {
    position: absolute;
    left: -9999px;
    top: -9999px;
}

select.custom-select {
    padding: 0px 20px;
    height: 50px;
    border-radius: 0;
    border: 1px solid $border;
    font-family: $ff-stack;
    background-color: transparent;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: url(/dist/assets/images/icons/angle-down.svg) no-repeat 100% #fff;
    background-position: right 20px center;

    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        border: 1px solid $primary;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}