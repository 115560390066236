.color-picker {
    display              : grid;
    grid-template-columns: repeat(11, 1fr);
    gap                  : 5px 5px;
  
    a {
      width           : 20px;
      height          : 20px;
      border-radius   : 9999px;
      background-color: var(--bg-color);
      border          : 2px solid $grey;
  
      &:hover {
        border: 2px solid $secondary;
      }
  
      &.active {
        border    : 2px solid $primary;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
      }
  
      &.disabled{
        background: repeating-linear-gradient(
          45deg,
          var(--bg-color),
          var(--bg-color) 2px,
          $grey 2px,
          $grey 4px
          );
        &:hover{
          cursor: no-drop;
        }
      }
    }
  
    &--wide {
      grid-template-columns: repeat(16, 1fr);
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {

  .color-picker {
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
  }
}