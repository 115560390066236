.checkout-nav {
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
    margin         : 0;
    padding        : 0;
    list-style-type: none;
  
    li {
      margin-right: 30px;
  
      a {
        display        : flex;
        flex-flow      : column;
        align-items    : center;
        justify-content: center;
  
        font-weight: 600;
        font-size  : 16px;
        line-height: 24px;
        color      : $black;
  
        img,
        svg {
          margin-bottom: 10px;
        }
  
        &:hover {
          text-decoration: none;
          color          : $primary;
        }
      }
  
      &:last-of-type {
        margin-right: 0;
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}