.c-slider {
    &__wrapper {
        margin-top: 60px;
        position: relative;
    }


    &__bttn {
        border: none;
        cursor: pointer;
        display         : flex;
        width           : 40px;
        height          : 40px;
        color           : $white;
        border-radius   : 999px;
        background-color: $secondary;
        align-items     : center;
        font-size       : 16px;
        justify-content : center;
        transition: all .3s ease;
    
        &:hover,
        &:active,
        &:focus {
            color: $white;
            background-color: darken($color: $secondary, $amount: 10%);
            text-decoration: none;
            transform: scale(1.05);
        }
    }

    &--left {
        position: absolute;
        left: -20px;
        top: calc(50% - 20px);
    }

    &--right {
        position: absolute;
        right: -20px;
        top: calc(50% - 20px);
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}