.image-banner {
    position        : absolute;
    bottom          : 0;
    left            : 0;
    background-color: $primary;
    color           : $white;
    padding         : 6px 10px;
    font-weight     : bold;
    font-size       : 14px;
    line-height     : 21px;
    border-radius   : 0 3px 0 0;
  
    &--detail {
      font-size  : 18px;
      line-height: 27px;
      padding    : 16px 20px;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}