.c-usp {
    display: flex;
    align-items: flex-start;
    
    &__icon {
        margin-right: 20px;
        font-size: 24px;
        color: $primary;
    }

    &__image {
        margin-right: 20px;
    }

    &__title {
        font-weight: 700;
        font-size: 18px;
        color: $secondary;
        margin-bottom: 4px;
    }

    &__text {
        margin: 0;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}