/* Fonts
-------------------------------------------------- */

$ff-stack: myriad-pro, sans-serif;

/* Colors
-------------------------------------------------- */
$white: #ffffff;
$grey-light: #FAFAFA;
$grey: #D3D6E0;
$grey-dark: #666666;
$grey-disabled: #b7b2b2;
$black: #222222;

$primary: #61A6C7;
$secondary: #23376C;

$text: #28394F;
$text_highlight: #4193BA;

$border: $grey;
$bg_blue: #E2F5FC;
$bg_blue_dark: $secondary;
$bg_grey: $grey-light;

$muted: #909AB5;

$danger-color: #D60727;
$danger-color-light: lighten($danger-color, 70%);
$warning-color: #ff6601;
$success-color: #1eaf3a;

$cookie-button-color: $white;
$cookie-button-bgcolor: $primary;
$cookie-link-color: $black;

/* Border radius
-------------------------------------------------- */
$border-radius: 50px;

/* Shadows
-------------------------------------------------- */
$shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);

/* Mixin's
-------------------------------------------------- */
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin fix-bttn {
  display:inline-block;
  text-align:center;
  
  &:after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}


/* Functions
-------------------------------------------------- */