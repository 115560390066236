.c-footer {
	p, a, h4 {
		color: $white;
	}

	&__logo {
		margin-bottom: 60px;
	}

	&__divider {
		width: 115px;
		height: 1px;
		background-color: $muted;
		margin-bottom: 60px;
	}

	&__address {
		margin-bottom: 60px;
		font-size: 16px;
		color: $white;
	}

	&__nav {
		margin-bottom: 60px;

		a {

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				color: $text_highlight;
			}
		}

		>ul {
			@include reset-list;
			margin-top: 12px;

			>li {
				margin-bottom: 8px;

				>a {
					font-weight: 700;
					color: $white;
					font-size: 14px;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color: $text_highlight;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&:last-child {
			margin-bottom: 0px;
		}
	}

	&__section {
		margin-bottom: 60px;

		&:last-child {
			margin-bottom: 0px;
		}
	}

	.sub-footer {
		margin: 24px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $muted;

		p {
			color: $muted;
		}

		a {
			font-weight: 300;
			font-size: 14px;
			line-height: 21px;
			color: $muted;
		}
	}
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
	.c-footer {
		.sub-footer {
			margin-top: 30px;
			flex-flow: column;
			align-items: center;

			a {
				text-align: center;
				padding: 4px 0;
			}
		}
	}
}