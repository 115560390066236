.footer-bar{
    background-color: $primary;
    padding: 40px 0;
  
    *{
        color: $white !important;
    
        p:last-child{
            margin-bottom: 0;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}