.c-banner {
   min-height: 300px;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}