.slider-button {
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 40px;
    height          : 40px;
    background-color: $primary;
    color           : $white;
    box-shadow      : 0 2px 8px rgba(0, 0, 0, 0.08);
  
    &:focus,
    &:active {
      background-color: $white;
      color           : $primary;
      text-decoration : none;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}