.c-cta {
	margin-top: 60px;
	border-radius: 20px;
	background-size: cover;
	padding: 32px;
	height: 400px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #23376c9a;
		border-radius: 20px;
	}

	* {
		color: $white !important;
		z-index: 100;
	}

	&__text {
		margin: 24px 0 32px 0;
	}

	&__link {
		&::before {
			color: $white;
		}
	}

	&--large {
		background-color: $secondary;
		padding: 60px;
	}

	p, a, i {
		font-size: 18px;
	}

	.t-h3 {
		font-size: 24px;
	}
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}