/*========================================================
=                       Typography                        =
=========================================================*/
.t-display {
  font-weight: bold;
  font-size  : 80px;
  line-height: 92px;
  color      : $secondary;
}

h1,
.t-h1,
.uk-h1 {
  font-weight: bold;
  font-size  : 40px;
  line-height: 48px;
  color      : $secondary;
}

h2,
.t-h2,
.uk-h2 {
  font-weight: bold;
  font-size  : 32px;
  line-height: 38.4px;
  color      : $secondary;
}

h3,
.t-h3,
.uk-h3 {
  font-weight: bold;
  font-size  : 24px;
  line-height: 28.8px;
  color      : $secondary;
}

h4,
.t-h4,
.uk-h4 {
  font-weight: bold;
  font-size  : 18px;
  line-height: 27px;
  color      : $secondary;
}

.t-highlight {
  color: $text_highlight;
}

h1, .t-h1, .uk-h1,
h2, .t-h2, .uk-h2,
h3, .t-h3, .uk-h3,
h4, .t-h4, .uk-h4,
h5, .t-h5, .uk-h5,
p, .cms, .text, .t-cms, .t-text {
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

p,
.cms,
.t-cms,
.text,
.t-text {
  font-size  : 18px;
  line-height: 27px;
  color      : $text;

  em {
    color: $text;
  }

  a {
    color: $text_highlight;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: darken($color: $text_highlight, $amount: 8%);
    }
  }

  table {
    border: 1px solid $border;
    border-collapse: collapse;

    th, td {
      padding: 8px 15px;
    }
  }

  strong {
    font-weight: 700;
    color: $text_highlight;
  }

  // size
  &--big {
    font-size: 18px;
    line-height: 27px;

    p {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &--small {
    font-size  : 14px;
    line-height: 21px;

    p {
      font-size  : 14px;
      line-height: 21px;
    }
  }

  &--tiny {
    font-size: 11px;
    
    p {
      font-size: 11px;
    }
  }

  // color
  &--white {
    color: $white;
    
    p {
      color: $white;
    }
  }

  &--grey {
    color: $grey;
    
    p {
      color: $grey;
    }
  }

  &--grey-dark {
    color: $grey-dark;
    
    p {
      color: $grey-dark;
    }
  }

  &--black {
    color: $black;
    
    p {
      color: $black;
    }
  }

  &--primary {
    color: $primary;
    
    p {
      color: $primary;
    }
  }

  &--success {
    color: $success-color;
    
    p {
      color: $success-color;
    }
  }

  &--warning {
    color: $warning-color;
    
    p {
      color: $warning-color;
    }
  }

  &--danger {
    color: $danger-color;
    
    p {
      color: $danger-color;
    }
  }

  // alingment
  &--center {
    text-align: center;

    h1, .t-h1, .uk-h1,
    h2, .t-h2, .uk-h2,
    h3, .t-h3, .uk-h3,
    h4, .t-h4, .uk-h4,
    h5, .t-h5, .uk-h5,
    p, .cms, .text, .t-cms, .t-text {
      text-align: center;
    }
  }

  &--right {
    text-align: right;

    h1, .t-h1, .uk-h1,
    h2, .t-h2, .uk-h2,
    h3, .t-h3, .uk-h3,
    h4, .t-h4, .uk-h4,
    h5, .t-h5, .uk-h5,
    p, .cms, .text, .t-cms, .t-text {
      text-align: right;
    }
  }
}

p{
  margin-bottom: 0;

  & + p{
    margin-top: 24px;
  }
}

.no-hover {
  &:hover {
    text-decoration: none;
  }
}

.input-errors {
  p {
    color: $danger-color;
    font-size: 14px;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

sub, sup {
  font-size: 100%;
}

address {
  font-size: 16px;
  line-height: 24px;
  color: $text;

  a {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: darken( $primary, 8%);
    }
  }
}

.t-date {
  margin-top: 10px;
  margin-bottom: 32px;
  font-size: 18px;
  color: $muted;
}

// general lists
ol, ul {
  padding-left: 20px;

  li {
    font-size: 16px;
    line-height: 24px;
    color: $text;

    &::marker{
      color: $primary;
      font-size: 20px;
    }
  }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
  .t-display {
    font-size  : 60px;
    line-height: 68px;
  }
  
  h1,
  .t-h1,
  .uk-h1 {
    font-size  : 32px;
    line-height: 38.4px;
  }
  
  h2,
  .t-h2,
  .uk-h2 {
    font-size  : 24px;
    line-height: 28.8px;
  }
  
  h3,
  .t-h3,
  .uk-h3 {
    font-size  : 18px;
    line-height: 27px;
  }
  
  h4,
  .t-h4,
  .uk-h4 {
    font-size  : 16px;
    line-height: 24px;
  }
}

// Mobile devices
@media (max-width: 640px) {
  .t-display {
    font-size  : 40px;
    line-height: 45px;
  }
}