.uk-breadcrumb {
  li {
    a {
      font-weight: 300;
      font-size  : 16px;
      line-height: 24px;
      color      : $black;

      &:hover {
        color: $primary;
      }
    }

    span {
      font-weight: 300;
      font-size  : 16px;
      line-height: 24px;
      color      : $black;
    }
  }

  > :nth-child(n + 2):not(.uk-first-column)::before {
    content: '>';
    color  : #e0e0e0;
    margin : 0px 15px;
  }
}



// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}