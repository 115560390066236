.check-list {
    display        : flex;
    flex-flow      : column;
    padding        : 0;
    margin         : 0;
    list-style-type: none;
    width          : 100%;
  
    li {
      display        : flex;
      justify-content: flex-start;
      align-items    : flex-start;
  
      i,
      svg {
        flex        : 0 0 20px;
        margin-right: 10px;
      }
    }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}