.filter-top {
    margin-top: 21px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
    .filter-top {
        // flex-direction: column;
        align-items: flex-start;

        &__right {
            margin: 30px 0 0 0;

            p {
                white-space: wrap;
            }
        }
    }
    
}

// Tablets
@media (max-width: 960px) {
    // .filter-top {
    //     flex-wrap: nowrap;

    //     .filter-checkbox {
    //         white-space: nowrap;

    //         &:first-child {
    //             margin-left: 60px;
    //         }

    //         &:last-child {
    //             margin-right: 120px;
    //         }
    //     }
    // }
}

// Mobile devices
@media (max-width: 640px) {
    .filter-top {
        .filter-checkbox {
            &:first-child {
                // margin-left: 30px;
            }
        }
    }
}