.c-text-img-big {
    min-height: 500px;

    &__inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 500px;
    }

    &__image-left {
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 50vw;
        height: 500px;
        border-radius: 0 20px 20px 0;
    }

    &__image-right {
        background-size: cover;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50vw;
        height: 500px;
        border-radius: 20px 0 0 20px;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
    .c-text-img-big {
        min-height: unset;
    
        &__inner-wrapper {
            min-height: unset;
            margin-bottom: 360px;
        }
    
        &__image-left {
            top: unset;
            bottom: 20px;
            width: calc(100vw - 60px);
            height: 300px;
        }
    
        &__image-right {
            top: unset;
            bottom: 20px;
            width: calc(100vw - 60px);
            height: 300px;
        }
    }
}

// Mobile devices
@media (max-width: 640px) {
    .c-text-img-big {
    
        &__inner-wrapper {
            margin-bottom: 240px;
        }
    
        &__image-left {
            width: calc(100vw - 12px);
            height: 200px;
        }
    
        &__image-right {
            width: calc(100vw - 12px);
            height: 200px;
        }
    }
}