.c-mobile-menu {
    .uk-offcanvas-bar {
		width     : 375px;
		right      : -375px;
		padding   : 0;
		position  : absolute;
		overflow-y: scroll;
		transition: right 0.6s ease;
    }
  
    &.uk-open {
		.uk-offcanvas-bar {
			right: 0;
		}
    }

    &__header {
      	position: relative;
    }
  
    .uk-offcanvas-close {
		position        : absolute;
		top             : 20px;
		right           : 20px;
    }
  
    &__content {
		display        : flex;
		flex-flow      : column;
		align-items    : stretch;
		justify-content: flex-start;
		width          : 100%;
		padding        : 30px 40px 30px 0;
		margin         : 0;
		list-style-type: none;
    }

	&__primary-nav {
		> ul {
			@include reset-list;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			
			> li {
				> a {
					padding: 15px 20px 15px 40px;
					font-size: 18px;
					font-weight: 700;
					color: $secondary;
					position: relative;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color: $text_highlight;

						&::after {
							content: '';
							width: 3px;
							background-color: $primary;
							position: absolute;
							left: 0px;
							top: 10px;
							bottom: 10px;
						}
					}

					&.is-active {
						color: $text_highlight;

						&::after {
							content: '';
							width: 3px;
							background-color: $primary;
							position: absolute;
							left: 0px;
							top: 10px;
							bottom: 10px;
						}
					}
				}
			}
		}

		.is-niv2 {
			display: none;
			@include reset-list;
			margin-top: 16px;
			margin-bottom: 0px;
			flex-direction: column;

			> li {
				> a {
					padding: 15px 20px 15px 60px;
					font-size: 18px;
					font-weight: 700;
					color: $secondary;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color: $text_highlight;
					}
				}
			}

			&.is-open {
				display: flex;
			}
		}
	
		.is-niv3 {
			display: none;
			@include reset-list;
			margin-top: 16px;
			margin-bottom: 0px;
			flex-direction: column;

			> li {
				> a {
					padding: 15px 20px 15px 80px;
				    cursor: pointer;
					font-size: 18px;
					line-height: 27px;
					font-weight: 400;
					color: $text;
					padding-right: 28px;
					position: relative;

					&::before {
						font-size: 16px;
						position: absolute;
						content: '\f178';
						font-family: 'Font Awesome 6 Pro';
						font-weight: bold;
						color: $primary;
						right: 5px;
						top: 15px;
						transition: right .3s ease;
					}

						&:hover,
						&:active,
						&:focus {
							text-decoration: none;
							color: $text_highlight;
						}
				}
			}

			&.is-open {
				display: flex;
			}
		}
	}

	&__secondary-nav {
		> ul {
			@include reset-list;
			display: flex;
			flex-direction: column;

			> li {
				margin: 0;

				> a {
					padding: 15px 15px 15px 40px;
					font-size: 16px;
					font-weight: 400;
					color: $text;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color: $text_highlight;
					}

					&.has-icon {
						display: flex;
						align-items: center;

						span {
							width: 24px;
							height: 24px;
							border-radius: 24px;
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: $primary;
							margin-right: 6px;
							transition: all .3s ease;

							i,
							.fa-solid,
							.fa-regular,
							.fa-light,
							.fa-thin,
							.fa-brands,
							svg {
								font-size: 14px;
								color: $white;
							}
						}

						&:hover,
						&:active,
						&:focus {
							text-decoration: none;

							span {
								background-color: darken($color: $primary, $amount: 10%);
							}
						}
					}
				}
			}
		}
	}
	
	&__languages {
        ul {
            @include reset-list;
            display: flex;
            padding-left: 40px;
			margin-top: 40px;

            li {
                a {
                    padding: 4px 16px;
                    text-transform: uppercase;
					font-size: 16px;
					font-weight: 400;
					color: $text;
                    border-left: 2px solid $border;
                 
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;
                        color: $primary;
                    }

                    &.is-active {
                        color: $primary;
                    }
                }

                &:first-child {
                    a {
                        padding-left: 0;
                        border-left: none;
                    }
                }
            }
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
	.c-mobile-menu {
		.uk-offcanvas-bar {
			width     : 100vw;
			right      : -100vw;
		}
	}
}