.order {
    padding              : 30px;
    border-radius        : 3px;
    border               : 1px solid $grey;
    display              : grid;
    grid-template-columns: 1fr 2.5fr;
    gap                  : 30px 30px;
  
    &__right {
        display              : grid;
        gap                  : 30px 30px;
        grid-template-columns: 1fr 1fr;
    }
  
    &__item {
        display              : grid;
        gap                  : 20px 20px;
        grid-template-columns: 1fr 1.5fr;
        align-items          : start;
    
        .image-block{
            background-size: contain;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}